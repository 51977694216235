.h-list {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  gap: $space-small;
  padding-left: 0;

  &-gap-0 {
    &-from-small {
      @include breakpoint-down(small) {
        gap: 0;
      }
    }
  }

  li {
    display: inline-flex;
    line-height: 1.6;

    &:before {
      content: '';
      display: flex;
      margin-right: $space-base;
      margin-top: $space-base * 0.4;
      position: relative;
    }
  }

  &--numbers {
    li {
      line-height: 2.5;

      @for $i from 1 through 6 {
        &:nth-of-type(#{$i}) {
          &:before {
            content: '0#{$i}';
            color: $color-secondary;
            font-weight: $font-weight-medium;
            margin-top: 0;
            margin-right: $space-large;
          }
        }
      }
    }
  }

  &--icons {
    @include breakpoint-down(medium) {
      flex-direction: row;
      flex-wrap: wrap;

      li {
        flex-basis: 49%;
      }
    }

    @include breakpoint-down(tiny) {
      li {
        flex-basis: 100%;
      }
    }

    li {
      line-height: 2;
    }
  }

  &__icon {
    margin-right: $space-huge;
    margin-top: $space-small;
  }

  &--bullet {
    li {
      &:before {
        background: currentColor;
        border-radius: 50%;
        margin-top: $space-base * 0.7;
        height: 0.75 * $space-small;
        min-width: 0.75 * $space-small;
      }
    }

    &-tynaarlo-primary {
      li {
        &:not(:first-child) {
          margin-top: $space-large;
        }

        &:before {
          background: $color-tynaarlo-secondary;
          border-radius: 50%;
          margin-top: $space-base * 0.7;
          height: 0.75 * $space-small;
          min-width: 0.75 * $space-small;
        }
      }
    }

    &.u-text-size--large {
      li {
        &:before {
          margin-top: $space-base * 0.5;
        }
      }
    }
  }

  &--arrow {
    li {
      &:before {
        background-image: url('/icons/arrow-right.svg');
        background-size: contain;
        background-repeat: no-repeat;
        height: 17px;
        margin-top: $space-base * 0.45;
        width: 18px;
      }
    }

    &-blue {
      li {
        &:before {
          background-image: url('/icons/arrow-right.svg');
          background-size: contain;
          background-repeat: no-repeat;
          filter: invert(47%) sepia(20%) saturate(7185%) hue-rotate(169deg) brightness(94%) contrast(105%);
          height: 17px;
          transform: translate3d(0, $space-base * 0.2, 0);
          width: 18px;
        }
      }
    }

    &.u-text-size--large {
      li {
        &:before {
          margin-top: $space-base * 0.15;

          @include breakpoint-up(medium) {
            margin-top: $space-base * 0.3;
          }
        }
      }
    }
  }

  &--large {
    li {
      line-height: 1.5;
    }
  }

  .h-stack--right & {
    margin-left: $space-huge;

    @include breakpoint-up(small) {
      margin-left: 0;
      padding-right: $space-container;
    }
  }

  &--related {
    gap: $space-medium;

    & li {
      align-items: baseline;
    }

    & a {
      max-width: 90%;
    }
  }
}
