.h-overlap {
  &-side {
    &--right {
      @include breakpoint-up(medium) {
        width: calc(100% + var(--layout-space));
      }

      @include breakpoint-up(giant) {
        width: calc(100% + calc(calc(100vw - 1800px + calc(var(--layout-space) * 2)) / 2));
      }

      @include breakpoint-up(giantplus) {
        width: 100%;
      }
    }

    &--left {
      @include breakpoint-up(medium) {
        width: calc(100% + var(--layout-space));
        transform: translate3d(calc(var(--layout-space) * -1), 0, 0);
      }

      @include breakpoint-up(giant) {
        width: calc(100% + calc(calc(100vw - 1800px + calc(var(--layout-space) * 2)) / 2));
        transform: translate3d(calc(calc(calc(100vw - 1800px) / -2) - var(--layout-space)), 0, 0);
      }

      @include breakpoint-up(giantplus) {
        width: 100%;
        transform: none;
      }

      &-full {
        @include breakpoint-up(giant) {
          width: calc(100% + calc(100vw - 1800px + var(--layout-space)));
          transform: translate3d(calc((100vw - 1800px + var(--layout-space)) * -1), 0, 0);
        }
      }
    }
  }

  &--full {
    &-medium {
      @include breakpoint-down(medium) {
        width: calc(100% + calc(var(--layout-space) * 4));
        transform: translate3d(calc(var(--layout-space) * -2), 0, 0);

        & * img {
          &.h-image--stretch {
            width: 100%;
          }
        }
      }

      @include breakpoint-down(small) {
        width: calc(100% + calc(var(--layout-space) * 4));
        transform: translate3d(calc(var(--layout-space) * -2), 0, 0);

        & * img {
          &.h-image--stretch {
            width: 100%;
          }
        }
      }

      @include breakpoint-down(tiny) {
        width: calc(100% + calc(var(--layout-space) * 3));
        transform: translate3d(calc(var(--layout-space) * -1.5), 0, 0);

        & * img {
          &.h-image--stretch {
            width: 100%;
          }
        }
      }
    }

    &-small {
      @include breakpoint-down(small) {
        width: calc(100% + calc(var(--layout-space) * 4));
        transform: translate3d(calc(var(--layout-space) * -2), 0, 0);

        & * img {
          &.h-image--stretch {
            width: 100%;
          }
        }
      }

      @include breakpoint-down(tiny) {
        width: calc(100% + calc(var(--layout-space) * 3));
        transform: translate3d(calc(var(--layout-space) * -1.5), 0, 0);

        & * img {
          &.h-image--stretch {
            width: 100%;
          }
        }
      }
    }
  }

  &--top {
    z-index: 1;
    @include breakpoint-up(medium) {
      margin-top: calc(#{$space-container} * -0.75);
    }
    @include breakpoint-up(large) {
      margin-top: calc(#{$space-container} * -1);
    }

    &-space-container-x2 {
      margin-top: calc(#{$space-container} * -0.5);

      @include breakpoint-up(medium) {
        margin-top: calc(#{$space-container} * -1);
      }
      @include breakpoint-up(large) {
        margin-top: calc(#{$space-container} * -2);
      }
    }

    &-extra {
      z-index: 1;
      @include breakpoint-up(medium) {
        margin-top: calc(#{$space-container} * -1.25);
      }
      @include breakpoint-up(large) {
        margin-top: calc(#{$space-container} * -1.5);
      }

      @include breakpoint-up(giantplus) {
        margin-top: calc(#{$space-container} * -1);
      }
    }

    &-double {
      z-index: 1;
      @include breakpoint-up(medium) {
        margin-top: calc(#{$space-container} * -0.5);
      }
      @include breakpoint-up(large) {
        margin-top: calc(#{$space-container} * -1);
      }
    }

    &-from-large {
      z-index: 1;

      @include breakpoint-up(large) {
        margin-top: calc(#{$space-container} * -1);
      }
    }
  }

  &--has-top {
    @include breakpoint-up(medium) {
      margin-top: calc(#{$space-container} * 0.75);

      & > .t-lines {
        height: calc(100% + calc(#{$space-container} * 0.75));
        transform: translate3d(0, calc(#{$space-container} * -0.75), 0);
      }
    }
    @include breakpoint-up(large) {
      margin-top: #{$space-container};

      & > .t-lines {
        height: calc(100% + #{$space-container});
        transform: translate3d(0, calc(#{$space-container} * -1), 0);
      }
    }

    &-from-large {
      @include breakpoint-up(large) {
        margin-top: #{$space-container};

        & > .t-lines {
          height: calc(100% + #{$space-container});
          transform: translate3d(0, calc(#{$space-container} * -1), 0);
        }
      }
    }
  }

  &--bottom {
    z-index: 1;
    @include breakpoint-up(medium) {
      margin-bottom: calc(#{$space-container} * -0.75);
    }
    @include breakpoint-up(large) {
      margin-bottom: calc(#{$space-container} * -1);
    }

    &-double {
      @include breakpoint-up(medium) {
        margin-bottom: calc(#{$space-container} * -1.5);
      }
      @include breakpoint-up(large) {
        margin-bottom: calc(#{$space-container} * -2);
      }

      &-from-large {
        @include breakpoint-up(large) {
          margin-bottom: calc(#{$space-container} * -2);
        }
      }
    }

    &-from-large {
      z-index: 1;

      @include breakpoint-up(large) {
        margin-bottom: calc(#{$space-container} * -1);
      }
    }

    &-at-insane {
      z-index: 1;

      @include breakpoint-up(insane) {
        margin-bottom: calc(#{$space-container} * -1);
      }
    }
  }

  &--has-bottom {
    @include breakpoint-up(medium) {
      margin-bottom: calc(#{$space-container} * 0.75);

      & > .t-lines {
        height: calc(100% + calc(#{$space-container} * 0.75));
      }

      &.h-overlap--has-top {
        & > .t-lines {
          height: calc(100% + calc(#{$space-container} * 1.5));
        }
      }
    }
    @include breakpoint-up(large) {
      margin-bottom: #{$space-container};

      & > .t-lines {
        height: calc(100% + #{$space-container});
      }

      &.h-overlap--has-top,
      &.h-overlap--has-top-from-large {
        & > .t-lines {
          height: calc(100% + calc(#{$space-container} * 2));
        }
      }
    }

    &-from-large {
      @include breakpoint-up(large) {
        margin-bottom: #{$space-container};

        & > .t-lines {
          height: calc(100% + #{$space-container});
        }

        &.h-overlap--has-top,
        &.h-overlap--has-top-from-large {
          & > .t-lines {
            height: calc(100% + calc(#{$space-container} * 2));
          }
        }
      }
    }
  }
}
