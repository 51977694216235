.m-link {
  display: flex;
  align-items: center;

  &__arrow {
    $height: $space-base * 1.8;
    align-items: center;
    display: inline-flex;
    justify-content: center;
    position: relative;

    &:before {
      background: $color-highlight;
      content: '';
      height: calc(100% + #{$height});
      left: $height / -2;
      bottom: $height / -2;
      transform: translate3d(0, 0, 0) scaleY(0);
      transform-origin: bottom;
      transition: opacity 0.2s $ease, transform 0.2s $ease, visibility 0.2s $ease;
      opacity: 0;
      position: absolute;
      pointer-events: none;
      visibility: hidden;
      width: calc(100% + #{$space-base} * 2);
      will-change: transform;
      z-index: 10;
    }

    & svg {
      color: $color-highlight;
      z-index: 11;
      transition: transform 0.3s $ease, color 0.3s $ease;
    }

    &--carousel {
      margin-left: 0;
      margin-top: $space-large;

      &:before {
        transform: translate3d(0, 100%, 0);
      }

      @include breakpoint-down(medium) {
        margin-left: auto;
        margin-top: 0;
        transform: translate3d(-100%, 0, 0);

        &:before {
          opacity: 1;
          transform: translate3d($space-base, 0, 0) scaleY(1);
          visibility: visible;
        }

        & svg {
          transform: translate3d(1.1rem, 0, 0);
          color: white;
        }
      }
    }
  }

  &--case {
    font-size: 1.125rem;
    text-decoration: none;
    gap: $space-base;
    margin-top: $space-large;

    line-height: $line-height-large;

    @include breakpoint-up(tiny) {
      font-size: $font-size-large;
    }

    @include breakpoint-up(medium) {
      font-size: $font-size-huge;
    }
  }

  &:hover {
    & .m-link__arrow {
      &:before {
        opacity: 1;
        transform: translate3d($space-base, 0, 0) scaleY(1);
        visibility: visible;
      }

      & svg {
        transform: translate3d(1.1rem, 0, 0);
        color: white;
      }
    }

    &.m-contact__submit {
      & .m-link__arrow {
        &:before {
          display: none;
        }

        & svg {
          transform: none;
          color: white;
        }
      }
    }
  }
}
