.m-content {
  opacity: 0;
  transform: translate3d(0, 20px, 0);
  transition: opacity 0.2s $ease-out-cubic, transform 0.4s $ease;
  visibility: hidden;
  will-change: transform;

  &--visible {
    min-height: var(--layout-space);
  }

  &.u-opacity--50 {
    opacity: 0 !important;
  }

  &[data-direction='none'] {
    transform: translate3d(0, 0, 0);
    transition: opacity 0.8s $ease-out-cubic;
  }

  &--reveal {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    visibility: visible;

    .m-contact__send & {
      transform: translate3d(0, 20px, 0);
      visibility: hidden;
      opacity: 0;
    }

    .m-contact__send.m-contact__send--reveal & {
      opacity: 1;
      transform: translate3d(0, 0, 0);
      visibility: visible;
      transition-delay: 1.6s;
    }

    &.u-opacity--50 {
      opacity: 0.5 !important;
    }
  }
}
