// Block reveal
.m-block-image {
  height: 100%;
  width: auto;
  z-index: 6;

  & > img {
    clip-path: inset(25% 0 0 0);
    opacity: 0;
    transition: clip-path 0.5s $ease, opacity .2s $ease, visibility .2s $ease, transform .2s $ease;
    transition-delay: var(--delay);
    visibility: hidden;
    will-change: clip-path;
  }

  &--large {
    & > img {
      transition: clip-path 1s $ease, opacity .4s $ease, visibility .4s $ease, transform .4s $ease;
    }
  }

  &-h--auto {
    height: auto;
  }

  &--reverse {
    & > img {
      clip-path: inset(0 0 25% 0);
    }
  }

  &--reveal,
  &--no-animation {
    & > img {
      clip-path: inset(0 0 0 0);
      opacity: 1;
      visibility: visible;
    }
  }
}
