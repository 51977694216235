.u-opacity {
  &--50 {
    opacity: 0.5 !important;
  }

  &--75 {
    opacity: 0.75 !important;
  }

  &--100 {
    opacity: 1 !important;
  }
}
