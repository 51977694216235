.m-carousel {
  @include breakpoint-down(medium) {
    height: var(--carousel-height);
    max-width: 100vw;
  }

  & > .t-grid {
    height: 100%;
  }

  &__card {
    align-self: flex-end;
    height: fit-content;
    transform: translateY(calc(100% + #{$space-container}));
    transition: padding 0.3s $ease, background 0.3s $ease, transform 0.8s $ease-in-out-base 0.4s;
    z-index: 1;

    @include breakpoint-down(medium) {
      display: none;
    }

    &:hover {
      padding: calc(var(--layout-space) * 1.25) var(--layout-space);
    }

    &-title {
      opacity: 0;
      transform: translate3d(0, 20px, 0);
      transition: opacity 0.2s $ease-out-cubic, transform 0.4s $ease;
      will-change: transform;
      grid-row: 1;
      grid-column: 1;

      &--current {
        opacity: 1;
        transform: initial;
        transition-delay: 0.8s;
      }

      &-container {
        display: grid;
        align-items: center;
      }
    }

    &-index {
      font-weight: 400;
      font-size: 1.125rem !important;
      display: flex;

      @include breakpoint-up(tiny) {
        font-size: 1.25rem !important;
      }

      @include breakpoint-up(medium) {
        font-size: $font-size-huge !important;
      }

      &-container {
        display: flex;
        position: relative;

        & > .m-carousel__card-index {
          position: absolute;
          opacity: 0;
          transform: translate3d(0, 20px, 0);
          transition: opacity 0.2s $ease-out-cubic, transform 0.4s $ease;
          will-change: transform;

          &:nth-child(1) {
            position: relative;
          }

          &--current {
            opacity: 1;
            transform: initial;
            transition-delay: 0.6s;
          }
        }
      }
    }
  }

  &__case {
    z-index: 1;

    @include breakpoint-down(medium) {
      height: fit-content;
      position: absolute;
      transform: translate3d(calc(calc(var(--total-case) - var(--index-case) - var(--current-case)) * 100%), 0, 0);
      transition: transform 0.8s $ease-in-out-base;
      height: var(--carousel-height);
      left: 0;
      padding-left: calc(2 * var(--layout-space));
      padding-right: calc(2 * var(--layout-space));

      & .m-carousel__title,
      .m-carousel__content {
        opacity: 1;
        transform: initial;
        transition-delay: 0.6s;
      }
    }

    &--current {
      @include breakpoint-up(medium) {
        z-index: 2;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    font-weight: $font-weight-medium;
    font-size: 1rem !important;
    opacity: 0;
    transform: translate3d(0, 20px, 0);
    transition: opacity 0.2s $ease-out-cubic, transform 0.4s $ease;
    will-change: transform;

    @include breakpoint-up(tiny) {
      font-size: 1.125rem !important;
    }

    @include breakpoint-up(large) {
      font-size: $font-size-large !important;
      min-height: $space-container * 3;
      margin-top: 0;
      justify-content: flex-end;
    }
  }

  &__title {
    font-size: 2rem !important;
    margin-top: $space-giant;
    opacity: 0;
    transform: translate3d(0, 20px, 0);
    transition: opacity 0.2s $ease-out-cubic, transform 0.4s $ease;
    will-change: transform;

    @include breakpoint-up(tiny) {
      font-size: 2.5rem !important;
    }

    @include breakpoint-up(small) {
      font-size: 3.125rem !important;
    }

    @include breakpoint-up(large) {
      font-size: $font-size-h1 !important;
    }

    @include breakpoint-only(large) {
      width: calc(100% + calc(var(--layout-space) * 2));
    }
  }

  &__tag {
    display: inline-block;
    font-weight: $font-weight-normal;
    opacity: 0;
    transform: translate3d(0, 20px, 0);
    transition: opacity 0.2s $ease-out-cubic, transform 0.4s $ease;
    transition-delay: 0.8s;
    will-change: transform;
  }

  &__stack {
    display: flex;
    height: 100%;
    flex-direction: column;
    padding: $space-container / 2 0;
    gap: $space-huge;

    @include breakpoint-up(large) {
      padding-top: $space-container * 1.5;
      padding-bottom: $space-container;
      gap: 0;
    }
  }

  &__pagination {
    display: flex;
    flex-direction: column;
    height: fit-content;
    z-index: 100;
    align-items: flex-end;
    margin-top: $space-container * 1.5;

    @include breakpoint-down(medium) {
      position: absolute;
      bottom: 1.5rem;
      left: 0;
      flex-direction: row;
      justify-content: center;
      min-height: 3rem;
    }
  }

  &__bullet {
    align-items: center;
    background: none;
    border-radius: 0;
    color: transparent;
    display: flex;
    font-size: $font-size-large;
    height: auto;
    margin-top: $space-base;
    margin-right: $space-large;
    opacity: 1;
    transition: color 0.2s $ease;
    width: auto;

    &:after {
      background: transparent;
      border: 1px solid white;
      border-radius: 50%;
      content: '';
      display: inline-flex;
      height: 10px;
      margin-left: $space-base;
      transition: background 0.2s $ease;
      width: 10px;
    }

    &:hover {
      cursor: pointer;
    }

    &:first-of-type {
      margin-top: 0;
    }

    &--current {
      color: white;

      &:after {
        background: white;
      }
    }

    @include breakpoint-down(medium) {
      font-size: 0;
      margin: auto 0;
      padding: $space-base;

      &:first-of-type {
        margin-top: auto;
      }

      &:after {
        margin-left: 0;
      }
    }
  }

  &__link {
    display: flex;
    width: fit-content;
    color: $color-highlight;
    text-decoration: none;
    font-size: $font-size-large;
    font-weight: $font-weight-medium;

    & > .m-card__arrow {
      margin-left: $space-base;
    }

    @include breakpoint-up(large) {
      display: none;
      visibility: none;
    }
  }

  &__image {
    position: absolute;
    opacity: 0.7;
    width: 100%;
    transform: translate3d(0, calc(calc(var(--total-case) - var(--index-case) - var(--current-case)) * -100.1%), 0);
    transition: clip-path 0.8s $ease-in-out-base, opacity 0.5s $ease-in-out-base, transform 0.8s $ease-in-out-base;
    height: 100%;

    @include breakpoint-down(medium) {
      opacity: 0.5;
      transform: translate3d(calc(calc(var(--total-case) - var(--index-case) - var(--current-case)) * 100%), 0, 0);
    }

    &-container {
      position: relative;
      overflow: hidden;

      @include breakpoint-down(medium) {
        min-width: 100vw;
        max-width: 100vw;
        height: var(--carousel-height);
        position: absolute;
        left: 0;
        transform: none;
      }
    }

    &:first-child {
      transform: translateY(-101%);
    }
  }

  &--reveal {
    & .m-carousel__image:first-child {
      transform: translate3d(0, calc(calc(var(--total-case) - var(--index-case) - var(--current-case)) * -100%), 0);

      @include breakpoint-down(medium) {
        transform: translate3d(calc(calc(var(--total-case) - var(--index-case) - var(--current-case)) * 100%), 0, 0);
      }
    }

    & .m-carousel__card {
      transform: translateY($space-container);
    }

    & .m-carousel__tag {
      opacity: 1;
      transform: initial;
    }

    & .m-carousel__case--current {
      @include breakpoint-up(medium) {
        & .m-carousel__title,
        .m-carousel__content {
          transition-delay: 0.8s;
          opacity: 1;
          transform: initial;
        }
      }
    }
  }
}
