/* Lettering */
.m-lettering {
  visibility: hidden;

  &--reveal {
    visibility: visible;
  }

  &--carousel {
    align-items: center;
    display: flex;
    gap: $space-base;
    flex-direction: column;
    font-weight: bold;
    position: relative;
    padding-bottom: $space-huge;

    & > * {
      bottom: 0;
      left: 0;
      position: absolute;
      top: 0;
      right:0;
      opacity: 0;
      visibility: hidden;
      transform: translateY(-10%);
    }

    & .m-lettering--active {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
      transition: all .3s ease-in-out;
    }
  }

  &__word {
    display: inline-block;
    overflow: hidden;
    vertical-align: text-top;
  }

  &__word-inner {
    display: inline-block;
    transform: translate3d(0, 150%, 0);
    transition: transform 1s $ease;
    vertical-align: text-top;

    .m-lettering--reveal & {
      transform: translate3d(0, 0, 0);
      transition-delay: calc(var(--delay) * 1ms);
    }

    .m-contact__send & {
      transform: translate3d(0, 150%, 0);
    }

    .m-contact__send.m-contact__send--reveal & {
      transform: translate3d(0, 0, 0);
      transition-delay: calc(calc(var(--delay) * 1ms) + 0.4s);
    }
  }

  &--break-rule {
    display: flex;
    flex-direction: column;
  }
}
