.m-navigation {
  border-bottom: 1px solid transparent;
  box-shadow: 0 2px 24px transparent;
  height: 4rem;
  max-height: 4rem;
  left: 0;
  position: absolute;
  top: 0;
  transition: color 0.1s $ease, transform 0.1s $ease, border-color 0.1s $ease, box-shadow 0.1s $ease;
  width: 100%;
  z-index: 10000;

  & > .t-container {
    position: relative;
    z-index: 100;

    .m-navigation--is-visible & {
      transform: translate3d(0, 1rem, 0);
      z-index: 100;
    }
  }

  &:after {
    background: transparent;
    bottom: 0;
    box-shadow: 0 1px 0 $color-line;
    content: '';
    height: 100%;
    opacity: 0;
    position: absolute;
    pointer-events: none;
    left: 0;
    transition: opacity 0.25s $ease, visibility 0.25s $ease;
    width: 100vw;
    z-index: 10;
  }

  & > * {
    z-index: 15;
  }

  &__item {
    overflow: hidden;

    a {
      font-size: 1.5rem;

      @include breakpoint-up(tiny) {
        font-size: 1.875rem;
      }

      @include breakpoint-up(small) {
        font-size: 2.125rem;
      }

      @include breakpoint-up(medium) {
        font-size: 2.75rem;
      }

      @include breakpoint-up(large) {
        font-size: $font-size-h2;
      }

      @include breakpoint-down(medium) {
        display: none;
      }
    }

    @for $i from 1 through 6 {
      .m-navigation--is-visible &:nth-of-type(#{$i}) .m-navigation__link {
        transition-delay: 0.15s * $i;
      }
    }

    .m-navigation__link {
      display: inline-block;
      transform: translate3d(0, 120%, 0);

      .m-navigation--is-visible & {
        transform: none;
        transition: transform 0.8s $ease;
      }
    }
  }

  &__menu {
    background: white;
    height: 100vh;
    left: 0;
    opacity: 0;
    overflow: auto;
    padding: $space-huge * 2 0;
    position: absolute;
    top: 0;
    transform: translate3d(0, -60px, 0);
    transition: visbility 0.25s $ease, opacity 0.25s $ease, transform 0.25s $ease;
    visibility: hidden;
    width: 100%;

    & > .t-container {
      height: 100%;

      & > .u-display--flex {
        height: 100%;
      }
    }
  }

  &__list {
    display: flex;
    flex-direction: column;

    z-index: 100;

    @include breakpoint-up(medium) {
      margin-top: auto;
      margin-bottom: auto;
    }

    & > * + * {
      margin-left: 0;
      margin-top: 2rem;

      @include breakpoint-down(small) {
        margin-top: 0.8rem;
      }
    }
  }

  &__link {
    margin-left: var(--layout-space);
    position: relative;
    text-decoration: none;
    text-shadow: 3px 0px 12px transparent;

    @include breakpoint-down(small) {
      margin-left: 0;
    }
  }

  & > .t-container {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
  }

  &__toggle {
    background: white;
    border: 1px solid transparent;
    border-radius: 50%;
    box-shadow: 0 0 20px transparent;
    cursor: pointer;
    height: 2.5rem;
    margin-left: $space-base;
    margin-right: calc(0.5 * var(--layout-space));
    padding: 0.5rem;
    position: relative;
    transition: border-color 0.25s $ease;
    width: 2.5rem;
    z-index: 6;
  }

  &__title {
    margin-left: $space-huge;
    transition: color 0.25s $ease;
    z-index: 6;

    & .m-navigation__contact {
      margin-left: $space-small;

      &:hover {
        &:before {
          left: -0.6 * $space-base;
        }
      }
    }

    @include breakpoint-down(tiny) {
      display: none;
    }
  }

  &__logo {
    margin-left: calc(0.5 * var(--layout-space));
    z-index: 6;

    @include breakpoint-down(medium) {
      margin-left: 0;
    }

    &:hover,
    &:active {
      color: currentColor;
    }
  }

  &__contact {
    margin-left: auto;
    margin-right: $space-base;
    position: relative;
    text-decoration: none;
    z-index: 6;

    &:before {
      @include bg-bar($spacing: 0, $background: $color-highlight, $easing: 0.2s $ease);
      left: -$space-small;
      top: -$space-tiny;
      max-width: calc(100% + #{$space-small} * 2);
      max-height: calc(100% + #{$space-tiny} * 2);
      transform: scaleY(0);
      transform-origin: bottom;
      z-index: -1;
    }

    &:hover {
      color: white;
      text-decoration-color: transparent;

      &:before {
        opacity: 1;
        transform: scaleY(1);
        visibility: visible;
      }

      &:after {
        opacity: 0;
        transform: scaleY(0);
        visibility: hidden;
      }
    }

    &:after {
      @include underline;
    }

    &-details {
      margin: auto;
      margin-right: var(--layout-space);
      opacity: 0;
      transform: translate3d(0, 10%, 0);
      visibility: hidden;

      .m-navigation--is-visible & {
        opacity: 1;
        transform: none;
        transition: opacity 0.4s $ease, visibility 0.4s $ease, transform 0.4s $ease;
        transition-delay: 0.5s;
        visibility: visible;
      }

      @include breakpoint-down(tiny) {
        margin: $space-huge * 1.5 0 0 var(--layout-space);
      }

      @include breakpoint-down(small) {
        margin-top: $space-huge;
      }

      @include breakpoint-down(small) {
        margin-bottom: $space-huge;
      }

      & .o-footer__locations {
        & a {
          display: inline-flex;
        }
      }

      & a {
        position: relative;

        &.u-text-size--h4 {
          &:before {
            left: -$space-small;
            top: -0.1rem;
            max-width: calc(100% + #{$space-small} * 2);
          }
        }

        &:before {
          @include bg-bar($spacing: 0, $background: $color-highlight, $easing: 0.2s $ease);
          left: -$space-tiny;
          top: -$space-tiny;
          max-width: calc(100% + #{$space-tiny} * 2);
          max-height: calc(100% + #{$space-tiny} * 2);
          transform: scaleY(0);
          transform-origin: bottom;
          z-index: -1;
        }

        &:hover {
          color: white;
          text-decoration-color: transparent;

          &:before {
            opacity: 1;
            transform: scaleY(1);
            visibility: visible;
          }
        }
      }

      .h-stack {
        & > div {
          display: flex;
          justify-content: center;

          @include breakpoint-down(medium) {
            margin-bottom: $space-base;

            &:last-of-type {
              margin-bottom: 0;
            }
          }

          @include breakpoint-up(small) {
            &:after {
              content: '|';
              display: inline-flex;
              margin: 3px $space-base 0;
              text-decoration: none;
            }

            &:last-of-type {
              &:after {
                display: none;
              }
            }
          }
        }
      }

      @include breakpoint-down(small) {
        margin-left: 0;
      }
    }

    &--light {
      color: $color-primary;
    }
  }

  &--light {
    color: $color-primary;

    &:after {
      color: $color-highlight;
    }

    & .m-navigation {
      &__contact {
        &:after {
          background: $color-highlight;
        }
      }

      &__toggle {
        &:hover {
          border-color: $color-highlight;
        }
      }
    }
  }

  &--dark {
    color: white;

    & .m-navigation {
      &__toggle {
        &:hover {
          border-color: $color-highlight;
        }
      }
    }
  }

  &--scrolled {
    background: white;
    color: $color-primary;
    opacity: 0;
    pointer-events: none;
    position: fixed;
    transform: translate3d(0, 0, 0);
    visibility: hidden;

    &-up {
      border-color: rgba($color-primary, 0.2);
      opacity: 1;
      pointer-events: all;
      visibility: visible;
      transition: color 0.25s $ease, opacity 0.25s $ease, visibility 0.25s $ease, box-shadow 0.25s $ease, transform 0.25s $ease;
    }

    & > .t-container {
      &:after {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &--is-visible {
    color: $color-primary;
    position: fixed;
    transform: translate3d(0, 0, 0);

    & > .t-container {
      &:after {
        opacity: 1;
        visibility: visible;
      }
    }

    &:before {
      visibility: visible;
      opacity: 0.4;
      transform: translate3d(0, 0, 0);
    }

    & .m-navigation {
      &__contact {
        &:after {
          background: $color-highlight;
        }
      }

      &__menu {
        align-items: center;
        display: flex;
        justify-content: center;
        opacity: 1;
        visibility: visible;
        transform: translate3d(0, 0, 0);
        transition: visbility, opacity, transform;
        transition-duration: 0.25s;
        transition-timing-function: $ease;
      }
    }
  }
}
