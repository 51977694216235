.h-color-block {
  height: 100%;
  width: 100%;

  &--full-screen {
    width: 100%;

    &::before {
      content: '';
      height: 100%;
      width: 100vw;
      position: absolute;
      background-color: inherit;
      margin-left: calc(var(--layout-space) * -1);

      @include breakpoint-up(giant) {
        margin-left: calc(calc(100vw - 1800px) / -2 - var(--layout-space));
      }
    }
  }

  &--1-3-right {
    position: absolute;
    top: 0;
    right: 0;
    display: none;
    background-color: $color-primary;

    @include breakpoint-up(large) {
      width: calc(calc(100% + var(--layout-space)) / 3);
      display: block;
    }

    @include breakpoint-up(giant) {
      width: calc(calc(calc(1800px + var(--layout-space)) / 3) + calc(calc(100% - 1800px) / 2));
    }
  }

  &--2-3-right {
    background-color: white;
    display: none;

    &::after {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      width: calc(var(--layout-space) + 1px);
      background-color: inherit;
      transform: translateX(calc(100% - 1px));
      height: 100%;
    }

    @include breakpoint-up(large) {
      display: block;
    }

    @include breakpoint-up(giant) {
      &::after {
        width: calc(calc(100vw - 1800px + calc(var(--layout-space) * 2)) / 2);
      }
    }

    & .t-lines__container {
      width: 100%;
      left: 0;
      transform: none;
      height: 6rem;
    }

    & .t-lines__line {
      background: #000;
      opacity: 0.12;

      &:nth-child(2) {
        left: 49.9%;
      }

      &:nth-child(3) {
        left: 99.85%;
      }

      &:nth-child(4) {
        display: none;
      }
    }
  }

  &--quote {
    background-color: white;
    height: calc(100% + calc(#{$space-container} - 25.5px));
    transform: translate3d(calc(var(--layout-space) * -1.5), calc(#{$space-container * -0.5} + 25.5px), 0);

    &::after {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      width: calc(var(--layout-space) * 3 + 1px);
      background-color: inherit;
      transform: translateX(calc(100% - 1px));
      height: 100%;
    }

    @include breakpoint-up(small) {
      transform: translateX(calc(var(--layout-space) * -2));

      &::after {
        width: calc(var(--layout-space) * 4 + 1px);
      }
    }

    @include breakpoint-up(medium) {
      height: calc(100% + calc(#{$space-container} * 1.75) - 36px);
      transform: translate3d(calc(var(--layout-space) * -2), calc(#{$space-container * -1} + 36px), 0);
    }

    @include breakpoint-up(large) {
      height: calc(100% + #{$space-container * 4} - 46px);
      transform: translateY(calc(#{$space-container * -1} - 46px));

      &::after {
        width: calc(var(--layout-space) + 1px);
      }
    }

    @include breakpoint-up(giant) {
      &::after {
        width: calc(calc(100vw - 1800px + calc(var(--layout-space) * 2)) / 2);
      }
    }

    & .t-lines__container {
      width: 100%;
      left: 0;
      transform: none;
    }

    & .t-lines__line {
      background: #000;
      opacity: 0.12;

      &:nth-child(2) {
        left: 49.9%;
      }

      &:nth-child(3) {
        left: 99.9%;
      }

      &:nth-child(4) {
        display: none;
      }
    }
  }

  &-primary {
    background-color: $color-primary;
  }

  &--white {
    background-color: white;

    & .t-lines__line {
      background: #000;
      opacity: 0.12;
    }

    @include breakpoint-down(medium) {
      display: none;
    }

    &.h-overlap-side--left {
      @include breakpoint-up(giant) {
        width: calc(100% + var(--layout-space) + ((100vw - 1800px) / 2));
        transform: translate3d(calc(((100vw - 1800px + calc(var(--layout-space) * 2)) / -2)), 0, 0);
      }
    }
  }

  &--highlight {
    background-color: $color-highlight;

    @include breakpoint-down(medium) {
      display: none;
    }

    &.h-overlap-side--right {
      @include breakpoint-up(giant) {
        width: calc(100% + var(--layout-space) + ((100vw - 1800px) / 2));
      }

      @include breakpoint-up(insane) {
        width: 100%;
      }
    }
  }

  &--tynaarlo {
    &-white {
      background-color: white;
      height: 100%;
      width: var(--layout-space);
      position: absolute;
      right: 0;
      top: 0;

      @include breakpoint-down(medium) {
        display: none;
      }

      @include breakpoint-up(giant) {
        width: calc(((100% - 1800px) / 2) + var(--layout-space));
      }
    }

    &-tertiary {
      background-color: $color-tynaarlo-tertiary;

      @include breakpoint-down(medium) {
        display: none;
      }

      &.h-overlap--bottom {
        @include breakpoint-up(medium) {
          height: calc(100% + #{$space-container} * 0.75);
        }
        @include breakpoint-up(large) {
          height: calc(100% + #{$space-container});
        }
      }

      &.h-overlap-side--right {
        @include breakpoint-up(giant) {
          width: calc(100% + var(--layout-space) + ((100vw - 1800px) / 2));
        }
      }
    }
  }

  &--p4ss {
    background-color: $color-p4ss-primary;
    left: calc(var(--layout-space) * -1);
    height: 100%;
    width: calc(100% + var(--layout-space));

    @include breakpoint-up(giant) {
      left: calc(((100vw - 1800px + calc(var(--layout-space) * 2)) / -2));
      width: calc(100% + var(--layout-space) + ((100vw - 1800px) / 2));
    }

    @include breakpoint-down(medium) {
      display: none;
    }

    &-top {
      background-color: $color-p4ss-primary;
      bottom: $space-container * -1;
      height: $space-container;
      position: relative;
      left: calc(var(--layout-space) * -1);
      width: calc(100% + var(--layout-space));

      @include breakpoint-up(giant) {
        left: calc(((100vw - 1800px + calc(var(--layout-space) * 2)) / -2));
        width: calc(100% + var(--layout-space) + ((100vw - 1800px) / 2));
      }

      @include breakpoint-down(medium) {
        display: none;
      }
    }

    &-bottom {
      background-color: $color-p4ss-primary;
      height: $space-container;
      left: calc(var(--layout-space) * -1);
      width: calc(100% + var(--layout-space));

      @include breakpoint-up(giant) {
        left: calc(((100vw - 1800px + calc(var(--layout-space) * 2)) / -2));
        width: calc(100% + var(--layout-space) + ((100vw - 1800px) / 2));
      }

      @include breakpoint-down(medium) {
        display: none;
      }
    }
  }

  .t-lines__container {
    width: 100%;
  }
}
