.h-image {
  &--stretch {
    height: 100%;
    width: auto;
    object-fit: cover;
  }

  &--cover {
    object-fit: cover;

    &:not(.m-visual) {
      width: 100%;
    }
  }

  &--blend {
    mix-blend-mode: screen;
  }

  &--lighten {
    mix-blend-mode: lighten;
  }

  &--contain {
    object-fit: contain;
  }

  &--position {
    &-top {
      object-position: top;
    }

    &-bottom {
      object-position: bottom;
    }

    &-left {
      object-position: left !important;
    }
  }

  &--iso {
    width: 50px;
    height: 50px;
    filter: grayscale(1);

    @include breakpoint-up(medium) {
      margin-left: auto;
    }
  }

  &--full-bg {
    @include breakpoint-down(medium) {
      display: none;
    }

    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;

    & img {
      height: 100%;
      left: 0;
      object-fit: cover;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  &--shadow {
    &::after {
      content: '';
      height: 100%;
      width: 90%;
      transform: translate3d(-50%, 0, 0);
      left: 50%;
      position: absolute;
      box-shadow: 0px 70px 60px -70px rgba($color: #000000, $alpha: 0.8);
      z-index: 1;
      bottom: 0;
    }
  }

  &--visual {
    z-index: 4;

    & > .h-ornament {
      z-index: -1;
    }
  }

  &--h-auto {
    height: auto;
  }

  &__case {
    &--nedmag {
      &:before {
        opacity: 0.3;
      }

      & img {
        object-fit: contain;
        object-position: 30% 20%;
      }
    }

    &--reducept {
      & img {
        object-position: 70% 50%;

        @include breakpoint-down(small) {
          object-position: 50% 50%;
        }
      }
    }

    &--tynaarlo {
      & img {
        object-position: 0% 15%;
      }
    }

    &--p4ss {
      &:before {
        opacity: 0.35;
      }

      & img {
        object-position: 0% 10%;
      }

      &:hover {
        &:before {
          opacity: 0.1;
        }
      }

      &-right {
        & img {
          object-position: 70% 10%;
        }
      }
    }
  }

  &__nedmag {
    &-saltlake {
      display: none;
      position: absolute;
      width: calc(calc(100% - calc(var(--layout-space) * 2)) / 2.4);
      bottom: 0;
      right: 0;
      transform: translate3d(calc(var(--layout-space) * -1), calc(100% - 4rem), 0);

      @include breakpoint-up(large) {
        display: block;
      }

      @include breakpoint-up(mega) {
        transform: translate3d(calc(var(--layout-space) * -1), calc(100% - 8rem), 0);
      }
    }

    &-rocks {
      content: '';
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      max-width: calc(100vw / 2 - calc(var(--layout-space) * 2));
      width: 100%;
      transform: translate3d(-6rem, -3rem, 0);

      @include breakpoint-up(giant) {
        transform: translate3d(0, 0, 0);
      }

      & img {
        position: absolute;
        left: 0;
        top: 0;
        object-fit: contain;
        transform: translate3d(0, -6rem, 0);
        z-index: 0;
      }
    }

    &-tablet {
      position: relative;

      @include breakpoint-up(large) {
        margin-left: calc(var(--layout-space) / 2);
        width: calc(100% - calc(var(--layout-space) / 2));
      }

      @include breakpoint-up(mega) {
        margin-left: 0;
        width: 100%;
        grid-column: 9/25;
      }

      &--stone-left {
        position: absolute;
        height: auto;
        left: 0;
        top: 0;
        width: 30%;

        & img {
          transform: translate3d(-60%, -20%, 0);
        }
      }

      &--stone-right {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 50%;
        height: auto;

        & img {
          transform: translate3d(35%, 50%, 0);
        }
      }
    }

    &-factory {
      max-width: $width-mega;
      left: 50%;
      transform: translateX(-50%);

      & img {
        object-fit: cover;
        object-position: bottom;
      }

      &::before {
        content: '';
        background: linear-gradient(180deg, $color-nedmag-primary, transparent);
        left: 0;
        top: 0;
        height: 20%;
        position: absolute;
        mix-blend-mode: darken;
        width: 100%;
        z-index: 1;
      }
    }
  }

  &__reducept {
    &-swirl {
      &--small {
        display: block;

        @include breakpoint-up(giant) {
          display: none;
        }
      }

      &--big {
        display: none;

        @include breakpoint-up(giant) {
          display: block;

          width: calc(100% + calc(var(--layout-space) * 2));
        }
      }
    }

    &--cell {
      &-left {
        position: absolute;
        left: 0;
        bottom: 0;
        transform: translate3d(calc(var(--layout-space) * -2.5), 20%, 0);

        width: calc(var(--layout-space) * 12);
        z-index: 3;

        @include breakpoint-up(medium) {
          width: calc(var(--layout-space) * 10);
        }

        @include breakpoint-up(large) {
          width: calc(var(--layout-space) * 6);
        }

        @include breakpoint-up(mega) {
          transform: translate3d(calc(var(--layout-space) * -2), 20%, 0);
        }
      }

      &-middle {
        height: fit-content;
        margin-top: calc(var(--layout-space) * -1);
      }

      &-right {
        height: fit-content;
        transform: translate3d(var(--layout-space), calc(#{$space-container} * 1), 0);
        z-index: 2;

        & img {
          object-position: var(--layout-space);
        }

        @include breakpoint-up(giant) {
          transform: translate3d(calc(var(--layout-space) * 3), calc(#{$space-container} * 1), 0);
          z-index: 2;

          & img {
            object-position: 0;
          }
        }
      }
    }

    &--vr {
      @include breakpoint-down(medium) {
        img {
          object-fit: contain;
          object-position: bottom;
          aspect-ratio: auto;
        }
      }

      @include breakpoint-up(large) {
        width: calc(100% + calc(var(--layout-space)) * 2);
        transform: translate3d(calc(var(--layout-space) * -2), 0, 0);
      }
    }
  }

  &__p4ss {
    @include breakpoint-up(large) {
      margin-top: $space-container;
    }
  }

  &-clip {
    &--right {
      @include breakpoint-up(large) {
        height: 240px;
        margin-top: $space-large;
        height: 400%;
        position: absolute;
        width: calc(100% + var(--layout-space));
        top: -280%;
      }

      @include breakpoint-up(giant) {
        right: calc(var(--layout-space) * -1.87);
        width: 100%;
      }

      @include breakpoint-up(insane) {
        width: 100%;
        right: 0;
      }

      &.h-image--signify {
        height: calc(100% + #{$space-container} * 3.5);
        width: 100%;
        margin-top: 0;
        top: -$space-container;
        z-index: 100;
      }
    }
  }

  &__xxl {
    @include breakpoint-up(mega) {
      &--react {
        object-position: bottom !important;
      }
    }
  }
}
