@mixin underline($height: 1px) {
  background: currentColor;
  bottom: 0;
  content: "";
  height: $height;
  left: 0;
  position: absolute;
  width: 100%;

  &:hover {
    background: $color-highlight;
  }
}