/* Ratio. */
.h-ratio {
  display: block;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 100%;

  &--auto {
    aspect-ratio: auto;
  }

  &--16-by-9 {
    &::before {
      @include aspect-ratio(16, 9);

      content: '';
      display: block;
    }

    &-from-medium {
      @include breakpoint-down(medium) {
        aspect-ratio: 16/9;
      }
    }
  }

  &--3-by-4 {
    aspect-ratio: 3/4;

    &-from-medium {
      @include breakpoint-down(medium) {
        aspect-ratio: 3/4;
      }
    }
  }

  &__item {
    border: 0;
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}
