/* Footer. */
.o-footer {
  overflow: hidden;
  position: relative;

  &--overflow {
    overflow: visible;
  }

  @include breakpoint-down(medium) {
    &.u-spacing-pt--container {
      padding-top: $space-huge !important;
    }
  }

  &__title {
    position: relative;
    z-index: 7;

    font-size: 1.5rem !important;

    @include breakpoint-up(tiny) {
      font-size: 2.125rem !important;
    }

    @include breakpoint-up(medium) {
      font-size: 2.875rem !important;
      margin: auto 0;
    }

    @include breakpoint-up(giant) {
      font-size: $font-size-h2 !important;
    }

    &:after {
      background-color: currentColor;
      content: '';
      display: flex;
      height: 2px;
      max-width: 0;
      width: 50px;
      margin-top: 1.5 * $space-base;
      transition: max-width 0.25s $ease;
      transition-delay: 1s;

      @include breakpoint-up(small) {
        margin-top: 4 * $space-base;
      }

      @include breakpoint-up(medium) {
        margin-top: 3 * $space-base;
      }

      @include breakpoint-up(large) {
        margin-top: 4 * $space-base;
      }

      @include breakpoint-up(huge) {
        margin-top: 5 * $space-base;
      }
    }

    &.m-lettering--reveal {
      &:after {
        max-width: 50px;
      }
    }
  }

  &__menu {
    ul {
      flex-direction: column;

      & li {
        font-size: inherit;
      }

      & > * + * {
        margin-left: 0;
        margin-top: $space-base;
      }
    }

    & a {
      position: relative;
      transition: color 0.2s $ease, text-decoration-color 0.2s $ease;

      & > span,
      & > svg {
        position: relative;
        z-index: 20;
      }

      &:before {
        @include bg-bar($spacing: 0, $background: $color-highlight, $easing: 0.2s $ease);
        left: -$space-tiny - 0.1rem;
        top: -$space-tiny;
        max-width: calc(100% + #{$space-tiny} * 2);
        max-height: calc(100% + #{$space-tiny} * 2);
        transform: scaleY(0);
        transform-origin: bottom;
        z-index: 10;
      }

      &:hover {
        color: white;
        text-decoration-color: transparent;

        &:before {
          opacity: 1;
          transform: scaleY(1);
          visibility: visible;
        }
      }
    }

    @include breakpoint-up(huge) {
      margin-left: $space-base;

      ul {
        flex-direction: row;

        & > * + * {
          margin-left: $space-base;
          margin-top: 0;
        }
      }
    }
  }

  &__bottom {
    padding-bottom: $space-container * 0.25;
    align-items: center;

    &.u-spacing-mt--container {
      margin-top: $space-huge * 2 !important;
    }

    @include breakpoint-down(small) {
      &.u-spacing-mt--container {
        margin-top: $space-huge * 1.5 !important;
      }
    }
  }

  &__locations {
    margin-top: $space-base;

    @include breakpoint-up(medium) {
      margin-top: 0;
    }

    & a {
      &:before {
        display: none;
      }

      & > span {
        align-items: center;
        display: inline-flex;

        &:before {
          @include bg-bar($spacing: 0, $background: $color-highlight, $easing: 0.2s $ease);
          left: -2 * $space-tiny;
          top: -$space-tiny;
          max-width: calc(100% + #{$space-tiny} * 4);
          max-height: calc(100% + #{$space-tiny} * 2);
          transform: scaleY(0);
          transform-origin: bottom;
          z-index: -1;
        }
      }

      &:hover {
        & > span {
          &:before {
            opacity: 1;
            transform: scaleY(1);
            visibility: visible;
          }
        }
      }

      svg {
        width: 14px;

        &:first-of-type {
          margin-right: $space-small;
        }

        &:last-of-type {
          margin-left: $space-small;
        }
      }
    }
  }

  &.h-overlap--has-top {
    margin-top: 0;

    @include breakpoint-up(large) {
      padding-top: $space-container * 1.5 !important;
    }

    @include breakpoint-up(giant) {
      padding-top: $space-container * 2 !important;
    }

    .h-ornament {
      @include breakpoint-up(large) {
        padding-top: $space-container * 0.75 !important;
      }

      @include breakpoint-up(giant) {
        padding-top: $space-container !important;
      }
    }
  }

  & .h-stack:not(.h-stack-on-medium--column) {
    flex-direction: column;

    @include breakpoint-down(tiny) {
      & > * + * {
        margin-top: $space-base;
      }
    }

    @include breakpoint-up(tiny) {
      flex-direction: row;
    }

    & > div {
      display: flex;
      justify-content: center;

      @include breakpoint-up(tiny) {
        &:after {
          content: '|';
          display: inline-flex;
          margin: 0.2rem $space-base auto $space-base;
          text-decoration: none;
        }

        &:last-of-type {
          &:after {
            display: none;
          }
        }
      }
    }
  }

  &__contact {
    & a {
      position: relative;
      transition: color 0.2s $ease, text-decoration-color 0.2s $ease;
      white-space: nowrap;

      & > span,
      & > svg {
        position: relative;
        z-index: 20;
      }

      &:before {
        @include bg-bar($spacing: 0, $background: $color-highlight, $easing: 0.2s $ease);
        left: -$space-tiny;
        top: -$space-tiny;
        max-width: calc(100% + #{$space-tiny} * 2);
        max-height: calc(100% + #{$space-tiny} * 2);
        transform: scaleY(0);
        transform-origin: bottom;
        z-index: 10;
      }

      &.u-text-size--h4 {
        &:before {
          top: -0.2rem;
          left: -$space-small;
          max-width: calc(100% + #{$space-small} * 2);
        }
      }

      &:hover {
        color: white;
        text-decoration-color: transparent;

        &:before {
          opacity: 1;
          transform: scaleY(1);
          visibility: visible;
        }
      }
    }

    @include breakpoint-down(medium) {
      margin-top: $space-base * 3;

      & a {
        font-size: $font-size-large;

        &.u-text-size--h4 {
          font-size: $font-size-large !important;
        }
      }
    }

    @include breakpoint-down(small) {
      & a {
        font-size: $font-size-base;
      }
    }

    &.u-spacing-mt--medium {
      margin-top: 1.5 * $space-huge !important;

      @include breakpoint-up(medium) {
        margin-top: 0;
      }
    }

    &--number {
      span {
        font-size: 1.375rem;

        @include breakpoint-up(small) {
          font-size: 1.625rem;
        }

        @include breakpoint-up(medium) {
          font-size: 1.875rem;
        }

        @include breakpoint-up(large) {
          font-size: $font-size-h4;
        }
      }
    }
  }

  &__socials {
    & ul {
      @include breakpoint-up(medium) {
        align-items: center;
      }

      @include breakpoint-down(medium) {
        flex-direction: column;
      }
    }

    & li {
      font-size: inherit;
    }

    & a {
      position: relative;
      transition: color 0.2s $ease, text-decoration-color 0.2s $ease;

      & > span,
      & > svg {
        position: relative;
        z-index: 20;
      }

      &:before {
        @include bg-bar($spacing: 0, $background: $color-highlight, $easing: 0.2s $ease);
        left: -$space-tiny - 0.1rem;
        top: -$space-tiny;
        max-width: calc(100% + #{$space-tiny} * 2);
        max-height: calc(100% + #{$space-tiny} * 2);
        transform: scaleY(0);
        transform-origin: bottom;
        z-index: 10;
      }

      &:hover {
        color: white;
        text-decoration-color: transparent;

        &:before {
          opacity: 1;
          transform: scaleY(1);
          visibility: visible;
        }
      }
    }

    &--icon {
      margin-top: auto;
      & a {
        &:before {
          left: -0.5rem;
          top: -1.6rem;
          max-height: calc(100% + 1.6rem);
          max-width: calc(100% + 0.3rem * 2);
        }
      }
    }

    @include breakpoint-down(medium) {
      margin-bottom: $space-huge;
      margin-top: $space-huge !important;

      & li {
        margin-right: auto;
      }
    }

    @include breakpoint-down(small) {
      margin-bottom: 0;
      margin-top: $space-base !important;
    }
  }

  &__iso {
    background: white;

    svg {
      width: 100%;
      height: 125px;
      margin-bottom: -4px;

      @include breakpoint-down(small) {
        height: 75px;
      }
    }
  }
}
