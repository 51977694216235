.u-spacing {
  &-mt {
    &--small {
      margin-top: $space-small !important;
    }

    &--medium {
      margin-top: $space-base !important;
    }

    &--large {
      margin-top: $space-large !important;
    }

    &--huge {
      margin-top: $space-huge * 0.25 !important;

      @include breakpoint-up(small) {
        margin-top: $space-huge * 0.5 !important;
      }

      @include breakpoint-up(medium) {
        margin-top: $space-huge * 0.75 !important;
      }

      @include breakpoint-up(large) {
        margin-top: $space-huge !important;
      }

      &-from-small {
        @include breakpoint-down(small) {
          margin-top: $space-huge * 0.5 !important;
        }
      }

      &-from-medium {
        @include breakpoint-down(medium) {
          margin-top: $space-huge * 0.5 !important;
        }
      }
    }

    &--layout-2 {
      margin-top: calc(2 * var(--layout-space));

      @include breakpoint-up(small) {
        margin-top: calc(1.5 * var(--layout-space));
      }

      @include breakpoint-up(medium) {
        margin-top: calc(var(--layout-space) * 0.5);
      }
    }

    &--container {
      margin-top: $space-container * 0.25 !important;

      @include breakpoint-up(small) {
        margin-top: $space-container * 0.5 !important;
      }

      @include breakpoint-up(medium) {
        margin-top: $space-container * 0.75 !important;
      }

      @include breakpoint-up(large) {
        margin-top: $space-container !important;
      }

      &-x2 {
        margin-top: $space-container * 2 !important;
      }

      &-from-small {
        @include breakpoint-down(small) {
          margin-top: $space-container * 0.5 !important;
        }
      }

      &-from-medium {
        @include breakpoint-down(medium) {
          margin-top: $space-container * 0.75 !important;
        }

        @include breakpoint-down(small) {
          margin-top: $space-container * 0.5 !important;
        }
      }

      &-from-tiny {
        @include breakpoint-down(tiny) {
          margin-top: $space-container * 0.25 !important;
        }
      }

      &-double {
        margin-top: $space-container * 0.25 !important;

        @include breakpoint-up(small) {
          margin-top: $space-container * 0.5 !important;
        }

        @include breakpoint-up(medium) {
          margin-top: $space-container * 1.5 !important;
        }

        @include breakpoint-up(large) {
          margin-top: $space-container * 2 !important;
        }
      }
    }

    &--collapse {
      margin-top: 0 !important;

      &-from-small {
        @include breakpoint-down(small) {
          margin-top: 0 !important;
        }
      }

      &-from-medium {
        @include breakpoint-down(medium) {
          margin-top: 0 !important;
        }
      }
    }

    &--quote {
      margin-top: calc(6rem + 180px + #{$space-huge} - var(--layout-space));

      &-case {
        margin-top: calc(180px + #{$space-huge} - var(--layout-space));
      }
    }
  }

  &-mb {
    &--collapse {
      margin-bottom: 0 !important;
    }

    &--small {
      margin-bottom: $space-small !important;
    }

    &--medium {
      margin-bottom: $space-base !important;
    }

    &--large {
      margin-bottom: $space-large !important;
    }

    &--huge {
      margin-bottom: $space-huge * 0.25 !important;

      @include breakpoint-up(small) {
        margin-bottom: $space-huge * 0.5 !important;
      }

      @include breakpoint-up(medium) {
        margin-bottom: $space-huge * 0.75 !important;
      }

      @include breakpoint-up(large) {
        margin-bottom: $space-huge !important;
      }
    }

    &--container {
      margin-bottom: $space-container * 0.25 !important;

      @include breakpoint-up(small) {
        margin-bottom: $space-container * 0.5 !important;
      }

      @include breakpoint-up(medium) {
        margin-bottom: $space-container * 0.75 !important;
      }

      @include breakpoint-up(large) {
        margin-bottom: $space-container !important;
      }
    }

    &--collapse {
      margin-bottom: 0 !important;

      &-from-small {
        @include breakpoint-down(small) {
          margin-bottom: 0 !important;
        }
      }

      &-from-medium {
        @include breakpoint-down(medium) {
          margin-bottom: 0 !important;
        }

        @include breakpoint-down(small) {
          margin-bottom: 0 !important;
        }
      }
    }

    &--auto {
      margin-bottom: auto !important;
    }
  }

  &-mr {
    &--collapse {
      margin-right: 0 !important;
    }

    &--small {
      margin-right: $space-small !important;
    }

    &--medium {
      margin-right: $space-base !important;
    }

    &--large {
      margin-right: $space-large !important;
    }

    &--huge {
      margin-right: $space-huge !important;
    }
  }

  &-ml {
    &--collapse {
      margin-left: 0 !important;

      &-from-small {
        @include breakpoint-down(small) {
          margin-left: 0 !important;
        }
      }
    }

    &--small {
      margin-left: $space-small !important;
    }

    &--medium {
      margin-left: $space-base !important;
    }

    &--large {
      margin-left: $space-large !important;
    }

    &--huge {
      margin-left: $space-huge !important;
    }
  }

  &-py {
    &--small {
      padding-top: $space-small !important;
      padding-bottom: $space-small !important;
    }

    &--medium {
      padding-top: $space-base !important;
      padding-bottom: $space-base !important;

      &-from-small {
        @include breakpoint-down(small) {
          padding-top: $space-base !important;
          padding-bottom: $space-base !important;
        }
      }
    }

    &--large {
      padding-top: $space-large !important;
      padding-bottom: $space-large !important;
    }

    &--huge {
      padding-top: $space-huge * 0.25 !important;
      padding-bottom: $space-huge * 0.25 !important;

      @include breakpoint-up(small) {
        padding-top: $space-huge * 0.5 !important;
        padding-bottom: $space-huge * 0.5 !important;
      }

      @include breakpoint-up(medium) {
        padding-top: $space-huge * 0.75 !important;
        padding-bottom: $space-huge * 0.75 !important;
      }

      @include breakpoint-up(large) {
        padding-top: $space-huge !important;
        padding-bottom: $space-huge !important;
      }
    }

    &--container {
      padding-top: $space-container * 0.5 !important;
      padding-bottom: $space-container * 0.5 !important;

      &:empty {
        display: none;
      }

      @include breakpoint-up(large) {
        padding-top: $space-container !important;
        padding-bottom: $space-container !important;
      }

      &-from-medium {
        @include breakpoint-down(medium) {
          padding-top: $space-container * 0.75 !important;
          padding-bottom: $space-container * 0.75 !important;
        }
        @include breakpoint-down(small) {
          padding-top: $space-container * 0.5 !important;
          padding-bottom: $space-container * 0.5 !important;
        }
      }

      &-from-small {
        @include breakpoint-down(small) {
          padding-top: $space-container * 0.5 !important;
          padding-bottom: $space-container * 0.5 !important;
        }
      }

      &-at-medium {
        @include breakpoint-up(medium) {
          padding-top: $space-container * 0.5 !important;
          padding-bottom: $space-container * 0.5 !important;
        }

        @include breakpoint-up(large) {
          padding-top: $space-container !important;
          padding-bottom: $space-container !important;
        }
      }

      &-x2 {
        padding-top: $space-container * 0.5 !important;
        padding-bottom: $space-container * 0.5 !important;

        @include breakpoint-up(medium) {
          padding-top: $space-container * 1 !important;
          padding-bottom: $space-container * 1 !important;
        }

        @include breakpoint-up(large) {
          padding-top: $space-container * 2 !important;
          padding-bottom: $space-container * 2 !important;
        }
      }
    }

    &--case-quote {
      padding-top: calc(#{$space-container * 0.5} - 25.5px) !important;
      padding-bottom: 0 !important;

      &:empty {
        display: none;
      }

      @include breakpoint-up(medium) {
        padding-top: calc(#{$space-container * 2} - 32px) !important;
      }

      @include breakpoint-up(large) {
        padding-bottom: $space-container * 2 !important;
      }
    }

    &--collapse {
      padding-top: 0 !important;
      padding-bottom: 0 !important;

      &-from-medium {
        @include breakpoint-down(medium) {
          padding-top: 0 !important;
          padding-bottom: 0 !important;
        }
      }

      &-from-small {
        @include breakpoint-down(small) {
          padding-top: 0 !important;
          padding-bottom: 0 !important;
        }
      }

      &-from-large {
        @include breakpoint-down(large) {
          padding-top: 0 !important;
          padding-bottom: 0 !important;
        }
      }
    }
  }

  &-px {
    &--small {
      padding-left: $space-small !important;
      padding-right: $space-small !important;
    }

    &--medium {
      padding-left: $space-base !important;
      padding-right: $space-base !important;
    }

    &--large {
      padding-left: $space-large !important;
      padding-right: $space-large !important;
    }

    &--huge {
      padding-left: $space-huge !important;
      padding-right: $space-huge !important;
    }

    &--collapse {
      padding-left: 0 !important;
      padding-right: 0 !important;

      &-from-medium {
        @include breakpoint-down(medium) {
          padding-left: 0 !important;
          padding-right: 0 !important;
        }
      }
    }
  }

  &-pt {
    &--small {
      padding-top: $space-small !important;
    }

    &--medium {
      padding-top: $space-base !important;
    }

    &--large {
      padding-top: $space-large !important;
    }

    &--huge {
      padding-top: $space-huge * 0.25 !important;

      @include breakpoint-up(small) {
        padding-top: $space-huge * 0.5 !important;
      }

      @include breakpoint-up(medium) {
        padding-top: $space-huge * 0.75 !important;
      }

      @include breakpoint-up(large) {
        padding-top: $space-huge !important;
      }

      &-from-small {
        @include breakpoint-down(small) {
          padding-top: $space-huge !important;
        }
      }
    }

    &--huge-x2 {
      @include breakpoint-down(large) {
        padding-top: 0 !important;
      }

      @include breakpoint-up(large) {
        padding-top: $space-huge * 3 !important;
      }
    }

    &--container {
      padding-top: $space-container * 0.5 !important;

      @include breakpoint-up(medium) {
        padding-top: $space-container * 0.75 !important;
      }

      @include breakpoint-up(large) {
        padding-top: $space-container !important;
      }

      &-x3 {
        @include breakpoint-up(medium) {
          padding-top: $space-container * 2.67 !important;
        }

        @include breakpoint-up(large) {
          padding-top: $space-container * 3 !important;
        }
      }

      &-from-small {
        @include breakpoint-down(small) {
          padding-top: $space-container * 0.5 !important;
        }
      }

      &-from-medium {
        @include breakpoint-down(medium) {
          padding-top: $space-container * 0.75 !important;
        }

        @include breakpoint-down(small) {
          padding-top: $space-container * 0.5 !important;
        }
      }

      &-half {
        &-from-small {
          padding-top: $space-container * 0.25 !important;
        }
      }
    }

    &--collapse {
      padding-top: 0 !important;

      &-from-medium {
        @include breakpoint-down(medium) {
          padding-top: $space-container * 0 !important;
        }
      }

      &-from-small {
        @include breakpoint-down(small) {
          padding-top: 0 !important;
        }
      }

      &-from-tiny {
        @include breakpoint-down(tiny) {
          padding-top: 0 !important;
        }
      }
    }
  }

  &-pb {
    &--small {
      padding-bottom: $space-small !important;
    }

    &--medium {
      padding-bottom: $space-base !important;
    }

    &--large {
      padding-bottom: $space-large !important;

      &-at-small {
        @include breakpoint-down(small) {
          padding-bottom: $space-large !important;
        }
      }
      &-at-large {
        @include breakpoint-up(large) {
          padding-bottom: $space-large !important;
        }
      }
    }

    &--huge {
      padding-bottom: $space-huge * 0.25 !important;

      @include breakpoint-up(small) {
        padding-bottom: $space-huge * 0.5 !important;
      }

      @include breakpoint-up(medium) {
        padding-bottom: $space-huge * 0.75 !important;
      }

      @include breakpoint-up(large) {
        padding-bottom: $space-huge !important;
      }
    }

    &--layout-space {
      padding-top: var(--layout-space);
      padding-bottom: var(--layout-space);
    }

    &--container {
      padding-bottom: $space-container * 0.5 !important;

      @include breakpoint-up(medium) {
        padding-bottom: $space-container * 0.75 !important;
      }

      @include breakpoint-up(large) {
        padding-bottom: $space-container !important;
      }

      &-x2 {
        padding-bottom: $space-container !important;

        @include breakpoint-up(medium) {
          padding-bottom: $space-container * 1.5 !important;
        }

        @include breakpoint-up(large) {
          padding-bottom: $space-container * 2 !important;
        }

        &-from-medium {
          @include breakpoint-down(medium) {
            padding-bottom: $space-container;
          }
        }
      }

      &-from-small {
        @include breakpoint-down(small) {
          padding-bottom: $space-container * 0.5 !important;
        }
      }
    }

    &--collapse {
      padding-bottom: 0 !important;

      &-from-tiny {
        @include breakpoint-down(tiny) {
          padding-bottom: 0 !important;
        }
      }

      &-from-small {
        @include breakpoint-down(small) {
          padding-bottom: 0 !important;
        }
      }

      &-from-medium {
        @include breakpoint-down(medium) {
          padding-bottom: 0 !important;
        }
      }
    }

    &--on-medium-collapse {
      @include breakpoint-down(small) {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
      }
    }
  }

  &-px {
    &--collapse {
      padding-bottom: 0 !important;

      &-from-small {
        @include breakpoint-down(small) {
          padding-bottom: 0 !important;
        }
      }
    }
  }
}
