/* Background color. */
.u-bg--primary {
  background-color: $color-primary;
}
.u-bg--light {
  background-color: tint($color-default, 90%);
}

/* Background image fit. */
.u-bg-fit {
  background-position: center center;
  display: block;
  height: 100%;
  width: 100%;

  & img {
    visibility: hidden;
  }
}
.u-bg-fit--cover {
  background-repeat: no-repeat;
  background-size: cover;
}
.u-bg-fit--contain {
  background-repeat: no-repeat;
  background-size: contain;
}
