.h-stack {
  $self: &;
  align-items: baseline;
  display: flex;
  flex-direction: row;

  & > p {
    position: relative;
    z-index: 10;
  }

  &-items {
    &--center {
      align-items: center;
    }
  }

  &-content {
    &--center {
      justify-content: center;
    }

    &--space-between {
      justify-content: space-between;
    }
  }

  &--wrap {
    &-from-medium {
      @include breakpoint-down(medium) {
        flex-wrap: wrap;
      }
    }
  }

  &--iso {
    gap: $space-base;

    svg {
      width: 4rem;
      height: 7rem;
    }
  }

  .h-stack--index & {
    @include breakpoint-down(medium) {
      flex-direction: column;
    }
  }

  &--column {
    flex-direction: column;
    gap: var(--layout-space);

    @include breakpoint-up(medium) {
      gap: calc(var(--layout-space) * 0.5);
    }

    &-text {
      gap: calc(2 * var(--layout-space));

      @include breakpoint-up(small) {
        gap: calc(1.5 * var(--layout-space));
      }

      @include breakpoint-up(medium) {
        gap: calc(var(--layout-space) * 0.5);
      }

      @include breakpoint-up(large) {
        gap: calc(var(--layout-space) * 0.5);
      }

      &-small {
        gap: calc(1.5 * var(--layout-space));

        @include breakpoint-up(small) {
          gap: calc(1 * var(--layout-space));
        }

        @include breakpoint-up(medium) {
          gap: calc(var(--layout-space) * 0.25);
        }

        @include breakpoint-up(large) {
          gap: calc(var(--layout-space) * 0.25);
        }
      }
    }
  }

  &--row {
    &-from-small {
      & .h-stack {
        @include breakpoint-only(small) {
          flex-direction: row;
        }
      }
    }
  }

  &--huge {
    gap: calc(var(--layout-space) * 2);

    @include breakpoint-up(medium) {
      gap: calc(var(--layout-space) * 0.75);
    }
  }

  &--medium {
    gap: calc(var(--layout-space) * 0.25);
  }

  &--center {
    align-items: center;
    justify-content: center;
  }

  &--right {
    margin-left: auto;

    &-from-large {
      @include breakpoint-up(large) {
        margin-left: auto;
      }
    }
  }

  &--w-full {
    width: 100%;
  }

  @include breakpoint-down(medium) {
    &-on-medium {
      &--row {
        align-items: baseline;
        flex-direction: column;
        flex-wrap: wrap;
        gap: calc(var(--layout-space) * 2);
      }

      &--column {
        flex-direction: column;
        gap: var(--layout-space);
      }
    }
  }

  @include breakpoint-down(small) {
    &-on-small {
      &--row {
        align-items: baseline;
        flex-direction: row;
        flex-wrap: wrap;
        gap: calc(var(--layout-space) * 2);
      }

      &--column {
        flex-direction: column;
        gap: var(--layout-space);
      }
    }
  }

  &__icon-list {
    @include breakpoint-down(tiny) {
      & > svg {
        height: 40px;
      }
    }
  }

  &__nedmag {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    flex-direction: column;
    gap: 1rem;

    &--line {
      margin: 0 auto;
      border: 1px solid black;
      width: 1px;
      height: 15%;
    }

    & > svg {
      @include breakpoint-down(small) {
        width: 25%;
        max-height: 120px;

        &:last-child {
          width: 280px;
        }
      }
    }

    &-card {
      @include breakpoint-up(huge) {
        width: calc(100% - calc(var(--layout-space) * 2));
      }
    }
  }

  &--link {
    & .m-card__arrow {
      transform: translate3d(-2.2rem, 0, 0);
    }
  }

  &__row {
    &-on-small {
      @include breakpoint-down(medium) {
        & a {
          display: flex;
          flex-direction: row;
          align-items: center;
          width: 100%;
        }
      }
    }
  }

  &__p4ss {
    margin-top: 3.5rem;

    @include breakpoint-up(medium) {
      margin-top: 4rem;
    }

    @include breakpoint-up(large) {
      margin-top: 0;
      gap: 4.5rem;
    }
  }

  &__case-title {
    display: inline-flex;
    flex-direction: column;
  }

  &--iso {
    flex-direction: row !important;
    gap: var(--layout-space);

    & > div {
      &:after {
        content: none !important;
      }
    }
  }
}
