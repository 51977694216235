/* Parallax */
.m-parallax {
  --depth: 20%;

  height: 100%;
  overflow: hidden;
  width: 100%;
  will-change: transform;

  &--depth-1 {
    --depth: 20%;

    @include breakpoint-up(medium) {
      --depth: 6rem;
    }
  }

  &--depth-2 {
    --depth: 40%;

    @include breakpoint-up(medium) {
      --depth: 12rem;
    }
  }

  &--depth-3 {
    --depth: 80%;

    @include breakpoint-up(medium) {
      --depth: 24rem;
    }
  }

  &--depth-4 {
    --depth: 120%;

    @include breakpoint-up(medium) {
      --depth: 36rem;
    }
  }

  &--vacancy {
    height: 400px;
    
    @include breakpoint-up(medium) {
      height: 600px;
    }
  }

  &__item {
    height: calc(100% + var(--depth)) !important;
  }
}