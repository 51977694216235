.m-header {
  & h1 {
    margin-top: $space-base * 2.75;
  }

  &__logo {
    bottom: 0;
    display: inline-flex;
    height: 143px;
    margin: 0 auto;
    left: 0;
    justify-content: center;
    position: absolute;
    right: 0;
    transform: scale(0.6);

    @include breakpoint-up(small) {
      height: 200px;
      transform: scale(1);
    }

    & svg {
      height: 100%;
    }
  }

  &__video {
    height: 60vh;
    mix-blend-mode: screen;
    position: relative;
    width: 100vw;

    @media screen and (max-height: 500px) {
      height: 50vh;
    }

    @media screen and (max-height: 700px) {
      height: 55vh;
    }

    @media screen and (max-height: 900px) {
      height: 60vh;
    }

    @media screen and (min-height: 1100px) {
      height: 70vh;
    }

    video {
      height: 100%;
      object-fit: cover;
      object-position: bottom;
      width: 100%;
    }

    &--regular {
      mix-blend-mode: unset;
    }

    &--bg {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
    }
  }

  &:before {
    content: '';
    background: $color-primary;
    height: calc(100% + #{$space-container * 0.25});
    left: 0;
    position: absolute;
    pointer-events: none;
    top: 0;
    width: 100%;
    z-index: 5;

    @include breakpoint-up(small) {
      height: calc(100% + #{$space-container * 0.5});
    }

    @include breakpoint-up(medium) {
      height: calc(100% + #{$space-container * 0.75});
    }

    @include breakpoint-up(large) {
      height: calc(100% + #{$space-container});
    }
  }

  & > * {
    z-index: 5;
    position: inherit;
  }
}
