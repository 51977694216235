.u-align {
  &--center {
    &-vertically {
      margin-bottom: auto;
      margin-top: auto;
    }

    &-horizontally {
      margin-left: auto;
      margin-right: auto;
    }
  }
}
