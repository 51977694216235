.m-cards {
  &--cases {
    position: relative;
    z-index: 1000;

    & .m-card {
      z-index: 10;
      height: 100%;
    }

    &-home {
      & .t-grid__item {
        @include breakpoint-up(large) {
          &:nth-of-type(even) {
            transform: translate3d(0, 120px, 0);
          }
        }
      }

      & .t-grid__item:not(.h-color-block) {
        z-index: inherit;
      }

      & .h-color-block {
        @include breakpoint-up(large) {
          height: calc(100% - 120px);
          transform: translate3d(0, 120px, 0);
        }
      }
    }

    &-overview {
      & > .t-grid__item {
        @include breakpoint-up(large) {
          &:nth-child(1) {
            margin-top: calc(#{$space-container} * -0.75);

            & > .m-card {
              position: relative;
              height: 100%;
            }
          }

          &:nth-last-child(1) {
            margin-bottom: calc(#{$space-container} * -0.75);

            & > .m-card {
              position: relative;
              height: 100%;
            }
          }
        }

        @include breakpoint-up(large) {
          &:nth-child(1) {
            margin-top: calc(#{$space-container} * -1);

            & > .m-card {
              position: relative;
              height: 100%;
            }
          }

          &:nth-last-child(1) {
            margin-bottom: calc(#{$space-container} * -1);

            & > .m-card {
              position: relative;
              height: 100%;
            }
          }
        }
      }
    }
  }
}
