.m-customer-quote {
  width: 100%;

  @include breakpoint-up(medium) {
    display: none;
  }

  &__text {
    margin-top: $space-container;

    &.h-stack > * + * {
      margin-top: $space-base;
      margin-bottom: $space-base;
    }
  }

  &__link {
    margin-bottom: 0;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
  }

  &__item {
    display: flex;
    flex-direction: column;
  }
}