.m-preloader {
  background: white;
  display: flex;
  height: 100vh;
  position: fixed;
  transition: opacity .2s $ease, visibility .2s $ease;
  width: 100vw;
  z-index: 10000;

  &__animation {
    width: 155px;
    height: 133px;
    margin: auto;
  }

  &--is-hidden {
    opacity: 0;
    visibility: hidden;
  }
}