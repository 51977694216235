.h-text {
  &--underline {
    &.m-lettering {
      & .m-lettering__word-inner {
        text-decoration: underline;
      }
    }
  }

  &__header {
    font-weight: $font-weight-normal;
    font-size: 1.3rem;
    line-height: $line-height-large;

    @include breakpoint-up(tiny) {
      font-size: 1.5rem;
    }

    @include breakpoint-up(small) {
      font-size: 2rem;
      font-family: $font-family-heading;
      font-weight: $font-weight-medium;
      margin-top: $space-base * 4;
    }

    @include breakpoint-up(medium) {
      font-size: $font-size-h4;
    }
  }

  &__intro {
    font-family: $font-family-heading;
    line-height: $line-height-large;
    font-size: 1.2rem;
    font-weight: lighter;

    &--regular {
      line-height: $line-height-large;
      font-weight: 400;
      font-size: 1.125rem;

      @include breakpoint-up(tiny) {
        font-size: 1.25rem;
      }

      @include breakpoint-up(medium) {
        font-size: $font-size-huge;
      }
    }

    &--bold {
      line-height: $line-height-huge;
      font-weight: 700;
      font-size: 1.125rem;

      @include breakpoint-up(tiny) {
        font-size: 1.25rem;
      }

      @include breakpoint-up(medium) {
        font-size: $font-size-huge;
      }
    }

    &--half-transparent {
      font-family: $font-family-heading;
      line-height: $line-height-large;
      font-size: 1.2rem;
      font-weight: lighter;
      opacity: 0.5;

      @include breakpoint-up(small) {
        font-size: 1.5rem;
      }

      @include breakpoint-up(medium) {
        font-size: $font-size-extra-huge;
      }
    }

    @include breakpoint-up(small) {
      font-size: 1.5rem;
    }

    @include breakpoint-up(medium) {
      font-size: $font-size-extra-huge;
    }
  }

  &__paragraph {
    font-size: 1rem;
    line-height: $line-height-large;

    &--heading {
      font-family: $font-family-heading;
    }

    @include breakpoint-up(tiny) {
      font-size: 1.125rem;
    }

    @include breakpoint-up(medium) {
      font-size: $font-size-large;
    }
  }

  &__card-usp {
    font-size: 1.25rem;
    font-weight: $font-weight-medium;

    @include breakpoint-down(small) {
      line-height: 1.4;
    }
  }

  &__quote {
    color: inherit;
    font-size: 1.125rem;
    font-weight: lighter;
    line-height: $line-height-large;
    opacity: 0.5;

    &--regular {
      opacity: 1;
    }

    @include breakpoint-up(tiny) {
      font-size: 1.5rem;
    }

    @include breakpoint-up(medium) {
      font-size: 1.75rem;
    }

    @include breakpoint-up(huge) {
      font-size: $font-size-huge;
    }

    @include breakpoint-up(giant) {
      font-size: 2.125rem;
    }

    &-link {
      font-size: 1.125rem !important;
      line-height: $line-height-large;
      font-weight: lighter !important;

      @include breakpoint-up(tiny) {
        font-size: 1.375rem !important;
      }

      @include breakpoint-up(small) {
        font-size: 1.625rem !important;
      }

      @include breakpoint-up(medium) {
        font-size: 1.875rem !important;
      }

      @include breakpoint-up(large) {
        font-size: $font-size-h4 !important;
      }

      @include breakpoint-up(huge) {
        width: 95%;
      }
    }
  }

  &__link-h3 {
    font-family: $font-family-heading;
    font-size: 1.5rem;
    font-weight: $font-weight-bold;

    @include breakpoint-up(tiny) {
      font-size: 1.75rem;
    }

    @include breakpoint-up(small) {
      font-size: 2rem;
    }

    @include breakpoint-up(medium) {
      font-size: $font-size-h3;
    }
  }

  &__link-h4 {
    font-size: 1.375rem;
    font-weight: $font-weight-bold;
    font-family: $font-family-heading;

    @include breakpoint-up(tiny) {
      font-size: 1.5rem;
    }

    @include breakpoint-up(small) {
      font-size: 2rem;
    }

    @include breakpoint-up(large) {
      font-size: 1.75rem;
    }

    @include breakpoint-up(giant) {
      font-size: $font-size-h4;
    }
  }
}
