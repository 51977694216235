.m-scroll-snap {
  &-sidebar {
    position: relative;
    margin: auto 0;

    &__content {
      display: flex;
      flex-direction: column;
      gap: 5rem;
      position: sticky;
      top: calc(50% - var(--sidebar-height));
    }

    &__list {
      gap: 1.75rem;
      display: flex;
      flex-direction: column;
      padding: 0;

      &--item {
        color: $color-highlight;
        display: flex;
        gap: 1rem;
        transition: color 0.2s ease-in-out;

        &-active {
          color: $color-primary;

          & .m-scroll-snap-sidebar__icon svg {
            circle {
              fill: transparent;
              stroke: #001c36;
            }

            path {
              stroke: #001c36;
            }
          }
        }

        &:hover {
          cursor: pointer;
        }
      }

      &--title {
        @include breakpoint-down(medium) {
          display: none;
        }
      }

      @include breakpoint-down(medium) {
        flex-direction: row;
        gap: 0;
        justify-content: center;
        display: none;
      }
    }

    &__icon {
      & svg {
        width: 32px;
        height: 32px;
      }

      @include breakpoint-down(medium) {
        display: none;
      }
    }

    @include breakpoint-up(large) {
      height: calc(100% - 36rem);
    }
  }

  &-wrapper {
    @include breakpoint-up(large) {
      display: flex;
      flex-direction: column;
      padding: 18rem 0;
    }
  }

  &-content {
    @include breakpoint-up(large) {
      padding: 12rem 0;

      &:last-child {
        padding-bottom: 0;
      }

      &:first-child {
        padding-top: 0;
      }
    }

    &__title {
      font-family: $font-family-heading;
      font-size: 1.375rem;

      @include breakpoint-up(tiny) {
        font-size: 1.5rem;
      }

      @include breakpoint-up(small) {
        font-size: 2rem;
      }

      @include breakpoint-up(large) {
        font-size: $font-size-h3;
      }
    }

    @include breakpoint-down(medium) {
      position: relative;
      opacity: 1;
      transform: none;
      top: 0;
    }
  }
}
