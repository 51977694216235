.m-video {
  & video {
    height: auto;
    width: 100%;
  }

  &--wrapper {
    left: 0;
    mix-blend-mode: lighten;
    position: sticky;
    top: 200px;
    width: 100%;

    & video {
      height: 100%;
      object-fit: contain;
      width: 100%;
    }
  }

  &--desktop {
    display: none;

    @include breakpoint-up(medium) {
      display: block;
    }
  }

  &--mobile {
    display: block;

    @include breakpoint-up(medium) {
      display: none;
    }
  }

  &--signify {
    height: 100%;
    position: relative;

    &-wrapper {
      position: sticky;
      top: 50px;
      bottom: 0;
      left: 0;
      height: 402px;
      width: 100%;
    }

    & video {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}