.m-contact {
  &__container {
    & > .m-contact__input {
      display: flex;
      position: relative;
      border: 0;
      border-bottom: 1px solid rgba($color-primary, 0.55);
      border-radius: 0;
      margin-top: $space-huge * 1.5;

      & > .m-contact__label {
        &--placeholder {
          position: absolute;
          top: 0;
          left: 0;
          padding: 0 0 0.75rem 0;
          color: $color-primary;
          font-family: $font-family-default;
          font-weight: $font-weight-medium;
          font-style: normal;
          font-size: $font-size-large;
          opacity: 0.5;
          transition: all 0.2s $ease-in-out-cubic;
        }
      }

      & > input,
      & textarea {
        background-color: transparent;
        border: 0;
        font-weight: $font-weight-medium;
        padding: 0 0 0.75rem 0;
        scrollbar-width: none;
        transition: all 0.3s $ease-in-out-cubic, height 0.3s $ease-in-out-cubic;

        &:not(:placeholder-shown) {
          & ~ .m-contact__label--placeholder {
            color: $color-primary;
            opacity: 0.5;
            font-size: $font-size-small;
            transform: translate3d(0, -$space-large, 0);
          }
        }

        &:hover {
          & ~ .m-contact__label--placeholder {
            color: $color-primary;
            opacity: 1;
          }
        }

        &:focus-within {
          & ~ .m-contact__label--placeholder {
            color: $color-primary;
            opacity: 1;
            font-size: $font-size-small;
            transform: translate3d(0, -$space-large, 0);
          }
        }
      }

      &::after {
        bottom: 0;
        content: '';
        left: 0;
        right: 0;
        height: 1px;
        position: absolute;
        width: 100%;
        background-color: transparent;
        transition: background-color 0.1s $ease-in-out-cubic;
      }

      &:hover {
        border-color: $color-highlight;

        & > .m-contact__label--placeholder {
          cursor: text;
          color: $color-primary;
          opacity: 1;
        }
      }

      &:focus-within {
        border-color: $color-highlight;
        border-width: 1px;

        &::after {
          background-color: $color-highlight;
        }
      }

      &:first-child {
        @include breakpoint-up(medium) {
          margin-top: $space-base * 4;
        }
      }
    }

    & textarea {
      resize: none;
      height: calc(#{$font-size-large} * #{$line-height-small} + 0.75rem);
      max-height: calc(#{$font-size-large} * #{$line-height-small} + 0.75rem);

      &:not(:placeholder-shown) {
        height: calc((#{$font-size-large} * #{$line-height-small}) * var(--height) + 0.75rem);
        max-height: calc((#{$font-size-large} * #{$line-height-small}) * 4 + 0.75rem);
      }

      &:focus-within {
        height: calc((#{$font-size-large} * #{$line-height-small}) * 4 + 0.75rem);
        max-height: calc((#{$font-size-large} * #{$line-height-small}) * 4 + 0.75rem);
        overflow-y: scroll;
      }

      &::-webkit-scrollbar {
        background-color: transparent;
        width: 4px;
      }

      &::-webkit-scrollbar-thumb {
        background: $color-primary;
        border-radius: 12px;
      }
    }
  }

  &__submit {
    background: none;
    border: none;
    margin-top: $space-large * 2;
    padding: $space-small $space-base;
    background-color: white;
    transition: color 0.25s $ease, background-color 0.25s $ease;
    position: relative;
    width: fit-content;
    text-decoration: none;

    & * {
      z-index: 2;
    }

    @include breakpoint-up(medium) {
      margin-top: calc(#{$space-base} * 4);
    }

    &:hover,
    :focus,
    :active {
      color: white;
      background-color: $color-highlight;
      cursor: pointer;
    }

    & .m-link__arrow {
      width: 26px;
      height: 20px;
    }
  }

  &__message {
    will-change: transform;

    &--remove {
      opacity: 0;
      transform: translate3d(0, 20px, 0);
      transition: opacity 0.2s $ease-out-cubic, transform 0.4s $ease;
      visibility: hidden;
    }
  }

  &__send {
    position: absolute;
    pointer-events: none;
    visibility: hidden;
    width: calc(100% - calc(var(--layout-space) * 2));
    top: 50%;
    transform: translateY(-50%);

    &--reveal {
      visibility: visible;
      pointer-events: initial;
    }
  }

  &__clip {
    &--left {
      @include breakpoint-up(large) {
        &::after {
          content: '';
          width: calc(100% / 15);
          height: 100%;
          position: absolute;
          background: inherit;
          transform: translateX(-100%);
          top: 0;
          left: 0;
        }
      }
    }
  }
}
