.u-order {
  &--1 {
    order: 1;
  }

  &--2 {
    order: 2;
  }

  &--3 {
    order: 3;
  }

  &--4 {
    order: 4;
  }

  &--5 {
    order: 5;
  }
}

.u-index {
  &--1 {
    z-index: 1;
  }

  &--2 {
    z-index: 2;
  }

  &--3 {
    z-index: 3;
  }

  &--over-ornament {
    z-index: 7;
  }
}
