/* Pager. */
.m-pager {
  margin: $space-small 0 $space-base;

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
  }

  &__item {
    &--prev,
    &--next {
      margin: 0 $space-small;
    }
  }

  &__page {
    align-items: center;
    border-radius: $border-radius;
    display: flex;
    font-weight: $font-weight-bold;
    height: 100%;
    padding: 0 $space-small;

    .m-pager__item--is-active > & {
      background-color: tint($color-primary, 90%);
      color: $color-primary;
      text-decoration: none;
    }
  }
}
