.m-quote {
  display: flex;
  flex-direction: column;
  margin: $space-small 0;

  &__icon {
    height: 180px;
    margin-bottom: -2 * $space-base;
    max-width: 180px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.1s $ease, visibility 0.1s $ease;
    transform: translateX(-2 * $space-base);

    @include breakpoint-down(medium) {
      height: 140px;
      max-width: 140px;
    }

    @include breakpoint-down(small) {
      min-width: 10px;
      height: 100px;
      max-width: 100px;
      transform: translateX(-1.2 * $space-base);
    }

    @include breakpoint-down(tiny) {
      min-width: 10px;
      height: 100px;
      max-width: 88px;
    }

    .t-grid__item.m-card--quote & {
      max-width: 120px;
      height: 120px;
      margin-bottom: -2.5 * $space-base;
      margin-top: -1.5 * $space-base;
      transform: translateX(-1.5 * $space-base);

      @include breakpoint-down(small) {
        height: 100px;
        max-width: 88px;
        transform: translateX(-1.2 * $space-base);
      }
    }

    & > svg {
      width: 100%;
      height: 100%;
      transform: translate3d(0px, 0px, 0px);
      content-visibility: visible;
    }

    & svg path {
      fill: currentColor;
    }

    &--reveal {
      opacity: 1;
      visibility: visible;
    }
  }

  & .h-text__quote {
    opacity: 1;
    font-family: $font-family-heading;
  }

  @include breakpoint-up(medium) {
    margin: 0;
  }

  @include breakpoint-down(medium) {
    & span.u-text-weight--bold {
      margin-top: $space-large !important;
    }
  }

  @include breakpoint-up(large) {
    padding: $space-container 0;
  }

  &--small {
    padding: var(--layout-space) 0;
  }

  &--large {
    & .m-card--quote {
      margin-top: 0;
    }
  }

  &--large {
    &:after {
      background: white;
      content: '';
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}
