.m-usps {
  &.u-spacing-mt--container {
    @include breakpoint-down(medium) {
      margin-top: 1.5 * $space-huge !important;
    }
  }

  &__item {
    background: transparent;
    display: inline-flex;
    justify-content: space-between;
    overflow: hidden;
    padding: $space-large 0;
    position: relative;
    transition: color 0.2s $ease, background 0.2s $ease, text-decoration-color 0.3s $ease;
    width: 100%;
    text-decoration: none;
    border-bottom: 1px solid #2b4d6c;

    & svg {
      height: 18px;
      width: auto;
    }

    &:before {
      $ease: $ease-out-quart;
      background: #34617f;
      content: '';
      left: 0;
      height: 100%;
      opacity: 0;
      position: absolute;
      pointer-events: none;
      top: 0;
      transform: translate3d(0, 120%, 0);
      transition: opacity 0.3s $ease, transform 0.4s $ease, visibility 0.3s $ease, color 0.3s $ease;
      visibility: hidden;
      width: 100%;
      z-index: -1;
    }

    & .m-card__arrow {
      transform: translate3d(-2.2rem, 0, 0);
    }

    &:hover {
      @include breakpoint-down(medium) {
        color: white;

        & .m-card__arrow {
          &::before {
            opacity: 1;
            transform: translate3d(1rem, 0, 0) scaleY(1);
            visibility: visible;
          }

          & > svg {
            color: white;
            transform: translate3d(1.1rem, 0, 0);
          }
        }
      }

      @include breakpoint-up(medium) {
        color: $color-highlight;

        &:before {
          opacity: 0.25;
          transform: translate3d(0, 0, 0);
          visibility: visible;
        }
      }
    }

    @include breakpoint-up(medium) {
      flex-direction: column;
      padding: $space-base * 2.5 $space-base;
      text-decoration: underline;
      justify-content: center;
      border-bottom: none;

      & svg {
        display: none;
      }
    }

    &--last {
      border-bottom: none;
    }
  }

  &__list {
    margin-top: $space-huge;

    & > * {
      display: flex;

      &:not(:first-child) {
        margin-top: $space-base;
      }
    }

    &--item {
      margin-left: $space-base;
    }

    &--arrow {
      display: flex;
      align-items: baseline;
      transform: translate3d(0, 2px, 0);

      & svg {
        width: 16px;
      }
    }
  }

  @include breakpoint-up(medium) {
    & > * {
      border-left: 1px solid rgba(white, 0.2);

      &:last-of-type {
        border-right: 1px solid rgba(white, 0.2);
      }
    }
  }
}
