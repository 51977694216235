.m-card {
  $self: &;
  $easing: 0.25s $ease;
  background: $color-primary;
  color: white;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: calc(2 * var(--layout-space)) calc(var(--layout-space) * 1.5);

  &--iso {
    @include breakpoint-up(medium) {
      transform: translateY(calc(var(--layout-space) * -3));
    }
  }

  & .h-stack {
    z-index: 7;
  }

  @include breakpoint-up(small) {
    padding: calc(2 * var(--layout-space));
  }

  @include breakpoint-up(large) {
    padding: var(--layout-space);
  }

  &.u-spacing-pb--container-from-small {
    @include breakpoint-down(small) {
      padding-bottom: $space-container !important;
    }
  }

  .u-spacing-py--container & {
    padding-left: calc(1.5 * var(--layout-space));
    padding-right: calc(1.5 * var(--layout-space));

    @include breakpoint-up(small) {
      padding-left: calc(2 * var(--layout-space));
      padding-right: calc(2 * var(--layout-space));
    }

    @include breakpoint-up(large) {
      padding-left: var(--layout-space);
      padding-right: var(--layout-space);
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    word-break: break-word;
  }

  & > {
    & * {
      z-index: 2;
    }

    & .o-header__bg {
      z-index: 1;
    }
  }

  & .h-ornament {
    color: white;
  }

  &__top {
    order: 1;
    margin-bottom: auto;
  }

  &__bottom {
    margin-top: calc(3 * var(--layout-space));
    max-width: $width-tiny;
    order: 2;
    pointer-events: none;

    @include breakpoint-up(medium) {
      margin-top: calc(2.5 * var(--layout-space));
    }
  }

  &__arrow {
    $height: $space-base * 1.8;
    align-items: center;
    display: inline-flex;
    justify-content: center;
    position: relative;
    width: fit-content;

    &:active,
    &:focus {
      color: white;
    }

    svg {
      height: 18px;
      position: relative;
      transition: transform 0.3s $ease;
      width: auto;
      will-change: transform;
      z-index: 20;
    }

    &:before {
      background: $color-highlight;
      content: '';
      height: calc(100% + #{$height});
      left: $height / -2;
      bottom: $height / -2;
      transform: translate3d(0, 0, 0) scaleY(0);
      transform-origin: bottom;
      transition: opacity 0.2s $ease, transform 0.2s $ease, visibility 0.2s $ease;
      opacity: 0;
      position: absolute;
      pointer-events: none;
      visibility: hidden;
      width: calc(100% + #{$space-base} * 2);
      will-change: transform;
      z-index: 10;
    }

    &--double {
      .m-card--link & svg {
        height: 21px;
      }

      &:before {
        left: -$space-base * 0.9;
        width: calc(100% + #{$space-base} * 2);
      }

      .m-card--link:hover & {
        & svg {
          transform: translate3d($space-base * 1.1, 0, 0);
        }

        &:before {
          max-width: calc(100% + #{$space-base} * 2.5);
        }
      }
    }

    @include breakpoint-up(small) {
      .m-card--link:hover &,
      &:hover {
        svg {
          transform: translate3d($space-base * 1.1, 0, 0);
        }

        &:before {
          opacity: 1;
          transform: translate3d($space-base, 0, 0) scaleY(1);
          visibility: visible;
        }
      }
    }
  }

  &__header {
    display: inline-flex;
    flex-direction: column;
    font-weight: $font-weight-medium;
    margin: 0;
    position: relative;

    @include breakpoint-up(small) {
      font-size: $font-size-base * 1.5;
      flex-direction: row;

      &:after {
        @include bg-bar($space-base, $color-highlight, 0.2s $ease);
        max-width: calc(100% + #{$space-base} * 2);
        max-height: calc(100% + #{$space-base} * 2);
        transform: scaleY(0.4) translate3d(0, -50%, 0);
        transform-origin: bottom;
        transition: opacity 0.2s $ease, visibility 0.2s $ease, transform 0.4s $ease;
      }
    }
  }

  @include breakpoint-up(small) {
    &__title {
      &:after {
        content: '/';
        margin-right: $space-small;
        margin-left: $space-tiny;
        display: inline-flex;
      }
    }
  }

  &__title,
  &__sector {
    z-index: 10;
  }

  &__sector {
    margin-top: $space-small;
    pointer-events: none;

    @include breakpoint-up(small) {
      margin: auto 0;
    }
  }

  &-size {
    &--large {
      & .m-card__bottom {
        margin-top: calc(4.5 * var(--layout-space));
      }
    }

    &--medium {
      & .m-card__bottom {
        margin-top: var(--layout-space);

        @include breakpoint-down(medium) {
          margin-top: calc(3 * var(--layout-space));
        }
      }
    }

    &--small {
      & .m-card__bottom {
        &:not(.h-stack) {
          margin-top: auto;
        }
      }

      & .m-card__arrow {
        &:before {
          top: -$space-base;
          height: calc(100% + #{$space-base} * 2);
        }
      }

      @include breakpoint-down(small) {
        & .h-stack svg {
          margin-top: 0;
        }
      }
    }

    &--p-large {
      @include breakpoint-up(large) {
        padding: calc(var(--layout-space) * 2) calc(var(--layout-space) * 2);
      }
    }

    &--py-large {
      @include breakpoint-up(large) {
        padding: calc(var(--layout-space) * 1.5) var(--layout-space);
      }
    }
  }

  &__stats {
    display: flex;
    flex-direction: column;

    @include breakpoint-down(medium) {
      flex-basis: 50%;
      margin-left: 0;
    }

    & > span {
      font-family: $font-family-heading;

      &:nth-of-type(odd) {
        font-weight: bold;
      }

      &:nth-of-type(even) {
        font-weight: lighter;
        margin-bottom: $space-base;
        margin-top: $space-small;

        @include breakpoint-up(medium) {
          margin-bottom: $space-huge;
        }
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  &--quote {
    position: relative;
    @include breakpoint-up(large) {
      margin-top: $space-container;
    }

    &-no-margin {
      margin-top: 0;

      & .h-text__card-usp {
        @include breakpoint-up(large) {
          margin-bottom: $space-base * 4;
        }
      }
    }

    & .h-stack {
      margin: auto 0;
    }
  }

  // Background image
  &--bg-image {
    position: relative;
    transition: all $easing;
    will-change: transform;
    padding: calc(var(--layout-space) * 1.5);

    @include breakpoint-up(small) {
      padding: var(--layout-space) calc(var(--layout-space) * 2);
    }

    @include breakpoint-up(large) {
      padding: var(--layout-space);
    }

    &:before {
      background: $color-primary-dark;
      content: '';
      height: 100%;
      left: 0;
      opacity: 0;
      position: absolute;
      transition: opacity $easing;
      top: 0;
      width: 100%;
      z-index: 5;
    }

    & > * {
      z-index: 10;
    }

    & .m-block-image {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      transition: transform 0.3s $ease;
      width: 100%;
      will-change: transform;
      z-index: 1;

      @include breakpoint-up(medium) {
        transform: scale(1.05);
      }

      & picture {
        display: flex;
      }

      & img {
        height: 100%;
        object-fit: cover;
        width: 100%;
      }
    }
  }

  @include breakpoint-up(large) {
    &--extended {
      &:after {
        background: inherit;
        content: '';
        height: 40%;
        position: absolute;
        top: 100%;
        width: calc(100% + 2 * var(--layout-space));
        z-index: 1;
      }

      &-h-full {
        height: 100%;

        &:after {
          height: 80%;
          bottom: 100%;
        }
      }

      &.m-card-clip--left {
        &:after {
          left: 0;
          margin-left: calc(-2 * var(--layout-space));
        }

        @include breakpoint-up(giant) {
          &:after {
            transform: translate3d(calc(((100vw - 1800px - calc(var(--layout-space) * 2)) / -2)), 0, 0);
          }
        }

        @include breakpoint-up(insane) {
          &::after {
            margin-left: 0;
            transform: none;
          }
        }
      }

      &.m-card-clip--right {
        &:after {
          margin-right: calc(-2 * var(--layout-space));
          right: 0;
        }

        @include breakpoint-up(giant) {
          &:after {
            transform: translate3d(calc(((100vw - 1800px - calc(var(--layout-space) * 2)) / 2)), 0, 0);
          }
        }

        @include breakpoint-up(insane) {
          &::after {
            margin-right: 0;
            transform: none;
          }
        }
      }
    }
  }
  &--extended {
    &:after {
      @include breakpoint-up(giant) {
        width: calc(100% + var(--layout-space) + ((100vw - 1800px) / 2));
      }

      @include breakpoint-up(insane) {
        width: 100%;
        transform: none;
        margin-left: 0;
      }
    }
  }

  &--size {
    &-small {
      & .h-text__card-usp {
        margin-bottom: 0;
      }
    }
  }

  &-clip {
    &--left,
    &--right,
    &--bg {
      overflow: visible;

      @include breakpoint-up(large) {
        &:before {
          background: inherit;
          content: '';
          height: calc(100% + 1px);
          position: absolute;
          top: 0;
          width: calc(1 * var(--layout-space));
        }

        &.h-overlap--bottom {
          &:before {
            height: 100%;
          }
        }
      }

      @include breakpoint-up(giant) {
        &:before {
          width: calc(calc(calc(100vw - 1800px) / 2) + var(--layout-space));
        }
      }

      @include breakpoint-up(insane) {
        &:before {
          content: none;
        }

        &.m-card--theme-signify-tertiary {
          &:before {
            content: '';
          }
        }
      }
    }

    &--left {
      @include breakpoint-up(large) {
        padding-right: calc(1.25 * var(--layout-space));
      }

      &:before {
        left: 0;
        margin-left: calc(-1 * var(--layout-space));

        @include breakpoint-up(giant) {
          margin-left: calc(calc(calc(100vw - 1800px) / -2) - var(--layout-space));
        }
      }

      &-double {
        &:before {
          margin-left: calc(-4 * var(--layout-space));
          width: calc(3 * var(--layout-space));

          @include breakpoint-up(giant) {
            width: calc(calc(calc(100vw - 1800px) / 2) + calc(var(--layout-space) * 2));
            margin-left: calc(calc(calc(100vw - 1800px) / -2) - calc(var(--layout-space) * 3));
          }

          @include breakpoint-up(insane) {
            content: '';
            margin-left: calc(var(--layout-space) * -2);
            width: var(--layout-space);
          }
        }
      }
    }

    &--right {
      &:before {
        margin-left: calc(-2 * var(--layout-space));
        margin-right: calc(-1 * var(--layout-space));
        right: 0;

        @include breakpoint-up(medium) {
          margin-left: 0;
        }

        @include breakpoint-up(giant) {
          margin-right: calc(calc(calc(100vw - 1800px) / -2) - var(--layout-space));
        }
      }
    }

    &-no-overflow {
      @include breakpoint-up(medium) {
        &:before {
          height: 100%;
        }
      }
    }
  }

  &__quote-block {
    @include breakpoint-up(large) {
      position: absolute;
      bottom: 0;
      transform: translateY(calc(50% + #{$space-container * 2}));
      z-index: 1;
    }
  }

  // Card is a link -> fully clickable
  &--link {
    cursor: pointer;
    padding: calc(var(--layout-space) * 2) calc(var(--layout-space) * 1.5);
    text-decoration: none;
    transition: background $easing, color $easing;

    @include breakpoint-up(small) {
      padding: calc(var(--layout-space) * 2) calc(var(--layout-space) * 2);
    }

    @include breakpoint-up(large) {
      padding: var(--layout-space);
    }

    & .h-stack-on-small--row,
    .h-stack-on-medium--row > * + * {
      margin-left: 0;
    }

    &.h-stack__center-on-small {
      h2 {
        margin-right: 0;
      }
    }

    h3 {
      margin-bottom: $space-base;
      transition: transform 0.3s $ease;
      will-change: transform;
    }

    .m-card__arrow {
      margin-top: $space-medium;

      @include breakpoint-up(small) {
        margin-top: $space-large;
      }
    }

    h3 {
      pointer-events: none;
    }

    & a {
      text-decoration: none;

      &:hover,
      &:focus,
      &:active {
        color: currentColor;
      }
    }

    @include breakpoint-up(small) {
      &:hover,
      &:focus,
      &:active {
        color: currentColor;
        & .m-card__header {
          &:after {
            opacity: 1;
            visibility: visible;
            transform: scaleY(1) translate3d(0, -50%, 0);
            transition: opacity 0.1s $ease, visibility 0.1s $ease, transform 0.3s $ease;
          }
        }

        h3 {
          transform: translate3d(0, calc(-0.5 * var(--layout-space)), 0);

          @include breakpoint-up(medium) {
            transform: translate3d(0, -$space-large, 0);
          }
        }

        & .m-block-image {
          transform: scale(1);
        }

        &:before {
          opacity: 0;
        }
      }
    }

    &:active,
    &:focus {
      color: $color-highlight;
    }

    &.m-card--theme-highlight,
    &.m-card--theme-secondary,
    &.m-card--theme-tertiary,
    &.m-card--theme-quaternary,
    &.m-card--theme-react,
    &.m-card--theme-go,
    &.m-card--theme-primary-shade,
    &.m-card--theme-secondary-shade,
    &.m-card--bg-image {
      &:hover {
        color: white;
      }

      &:active,
      &:focus {
        color: white;
      }
    }

    & svg {
      height: 18px;
      width: auto;

      &.m-card__arrow--double {
        height: 23px;
      }
    }

    & .m-logo--no-hover svg {
      max-width: 170px;
      height: auto;
    }

    & .m-card__bottom {
      & .m-card__arrow {
        margin-top: 1.5 * $space-base;

        @include breakpoint-up(medium) {
          margin-top: 1.5 * $space-base;
        }
      }
    }
  }

  &--usp {
    @include breakpoint-down(tiny) {
      padding: calc(1 * var(--layout-space)) calc(2 * var(--layout-space));
    }

    @include breakpoint-up(tiny) {
      padding: var(--layout-space);
    }

    &#{$self}--theme-white {
      h3 {
        color: $color-secondary;
      }
    }
  }

  &--logos {
    &-uneven {
      @include breakpoint-down(tiny) {
        grid-column: var(--column-start) / calc(var(--column-end) + 1);
      }
    }

    @include breakpoint-up(medium) {
      justify-content: center;
    }

    @include breakpoint-down(medium) {
      padding: calc(var(--layout-space) * 1.5) calc(1.5 * var(--layout-space));

      &:first-of-type {
        margin-top: calc(2 * var(--layout-space));
      }
    }

    .h-stack {
      gap: $space-huge;
      height: 100%;
      justify-content: space-around;

      @include breakpoint-down(tiny) {
        flex-direction: column;
        gap: 3rem;
      }
    }

    &.m-card--logos-hoverable {
      .h-stack {
        @include breakpoint-down(medium) {
          gap: 0;
        }
      }

      @include breakpoint-down(medium) {
        padding: calc(2 * var(--layout-space));
      }

      @include breakpoint-down(small) {
        padding: calc(var(--layout-space) * 2) calc(var(--layout-space) * 2);
      }

      @include breakpoint-down(tiny) {
        padding: calc(var(--layout-space) * 2) calc(var(--layout-space) * 1.5);
      }
    }
  }

  &--vacancy {
    height: 100%;

    p,
    span {
      font-size: $font-size-large;
      line-height: 1.5;
    }

    .h-stack {
      height: 100%;
    }

    h2 {
      margin-bottom: auto;
    }

    p {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  &--tynaarlo {
    @include breakpoint-up(large) {
      padding-top: 0;
      justify-content: flex-end;
    }

    &-black {
      @include breakpoint-down(medium) {
        height: fit-content;
        align-self: flex-end;
      }
    }
  }

  &--absolute {
    @include breakpoint-up(large) {
      bottom: 0;
      left: 0;
      position: absolute;
      width: 100%;
    }
  }

  // Themes
  &--theme {
    &-light {
      background: $color-light;
      color: $color-primary;
    }

    &-black-transparent {
      background: rgba(black, 0.78);
    }

    &-highlight {
      background: $color-highlight;
      border-bottom: 1px solid transparent;
      transition: color 0.3s $ease, background 0.3s $ease, border-color 0.3s $ease;

      & a:not(.m-carousel__card-title) {
        transition: color 0.3s $ease;
      }

      & .m-card__arrow {
        @include breakpoint-up(medium) {
          margin-bottom: $space-base;
        }

        @include breakpoint-down(medium) {
          margin-top: $space-base;
        }

        &:hover {
          color: $color-highlight;
        }

        &:before {
          background: white;
        }
      }

      &#{$self}--link {
        &:hover,
        &:focus,
        &:active {
          &:not(.m-card--image) {
            background: $color-light;
            color: $color-primary;

            & .m-card__arrow {
              color: $color-light;

              &:before {
                background: $color-highlight;
              }
            }
          }

          & .m-card__arrow {
            color: $color-highlight;
          }
        }

        & .m-card__arrow {
          &:before {
            background: transparent;
          }
        }

        &.o-header--reveal {
          & .m-card__arrow {
            &:before {
              background: white;
            }
          }
        }
      }

      & #{$self}__stats {
        & span {
          &:nth-of-type(even) {
            color: darken($color-highlight, 25%);
          }
        }
      }
    }

    &-signify-tertiary {
      background: $color-signify-tertiary;
      height: 100%;
      position: relative;
    }

    &-transparent {
      background-color: transparent;
      color: $color-primary;

      & .h-ornament {
        color: $color-primary;
      }

      & #{$self}__stats {
        & > span {
          &:nth-of-type(odd) {
            color: $color-secondary;
          }

          &:nth-of-type(even) {
            color: $color-primary;
          }
        }
      }

      &#{$self}--link {
        &:hover,
        &:focus,
        &:active {
          & .m-card__arrow {
            svg {
              filter: invert(100%) sepia(2%) saturate(9%) hue-rotate(72deg) brightness(103%) contrast(100%);
            }
          }
        }
      }
    }

    &-white {
      background: white;
      color: $color-primary;

      & .h-ornament {
        color: $color-primary;
      }

      & #{$self}__stats {
        & > span {
          &:nth-of-type(odd) {
            color: $color-secondary;
          }

          &:nth-of-type(even) {
            color: $color-primary;
          }
        }
      }

      &#{$self}--link {
        &:hover,
        &:focus,
        &:active {
          & .m-card__arrow {
            svg {
              filter: invert(100%) sepia(2%) saturate(9%) hue-rotate(72deg) brightness(103%) contrast(100%);
            }
          }
        }
      }
    }

    &-black {
      background: black;
      color: white;

      &-transparent {
        background: #101419c9;
        color: white;
      }
    }

    &-black-white {
      background: white;
      color: $color-primary;

      &.m-card--usp {
        h4 {
          color: $color-primary;
        }
      }
    }

    &-react {
      background: $color-react;
      color: white;

      & .m-card__arrow {
        &:hover {
          color: $color-react;
        }

        &:before {
          background: white;
        }
      }

      &:hover,
      &:focus,
      &:active {
        & .m-card__arrow {
          color: $color-react;
        }
      }
    }

    &-go {
      background: $color-go;
      color: white;

      & .m-card__arrow {
        &:hover {
          color: $color-go;
        }

        &:before {
          background: white;
        }
      }

      &:hover,
      &:focus,
      &:active {
        & .m-card__arrow {
          color: $color-go;
        }
      }

      & .m-logo {
        max-width: 140px;

        @include breakpoint-up(large) {
          transform: translateX(-1rem);
        }
      }
    }

    &-secondary {
      background: $color-secondary;

      &-from-small {
        @include breakpoint-down(medium) {
          background-color: $color-secondary;
        }
      }

      & a {
        color: white;

        &:hover {
          color: $color-secondary;

          &:before {
            background: white;
          }
        }
      }

      & .m-card__arrow {
        &:hover {
          color: $color-secondary;
        }

        &:before {
          background: white;
        }
      }

      &#{$self}--link {
        &:hover,
        &:focus,
        &:active {
          background: $color-secondary;

          & .m-card__arrow {
            color: $color-secondary;

            &:before {
              background: white;
            }
          }
        }
      }

      & #{$self}__stats {
        & > span {
          &:nth-of-type(even) {
            color: darken($color-secondary, 25%);
          }
        }
      }
    }

    &-tertiary {
      background: $color-tertiary;

      & .m-card__arrow {
        &:hover {
          color: $color-tertiary;
        }

        &:before {
          background: white;
        }
      }

      &#{$self}--link {
        &:hover,
        &:focus,
        &:active {
          background: $color-tertiary;

          & .m-card__arrow {
            color: $color-tertiary;
          }
        }
      }

      & #{$self}__stats {
        & > span {
          &:nth-of-type(even) {
            color: darken($color-tertiary, 25%);
          }
        }
      }
    }

    &-quaternary {
      background: $color-quaternary;

      & .m-card__arrow {
        &:hover {
          color: white;
        }

        &:before {
          background: white;
        }
      }

      &#{$self}--link {
        &:hover,
        &:focus,
        &:active {
          background: $color-quaternary;

          & .m-card__arrow {
            color: $color-quaternary;
          }
        }
      }

      & #{$self}__stats {
        & > span {
          &:nth-of-type(even) {
            color: darken($color-quaternary, 25%);
          }
        }
      }
    }

    &-primary-shade {
      background: $color-primary-shade;

      &#{$self}--link {
        &:hover,
        &:focus,
        &:active {
          background: lighten($color-primary-shade, 5%);
        }
      }

      & #{$self}__stats {
        & > span {
          &:nth-of-type(even) {
            color: darken($color-primary-shade, 25%);
          }
        }
      }
    }

    &-secondary-shade {
      background: $color-secondary-shade;

      &#{$self}--link {
        &:hover {
          background: lighten($color-secondary-shade, 5%);
        }
      }

      & #{$self}__stats {
        & > span {
          &:nth-of-type(even) {
            color: darken($color-secondary-shade, 25%);
          }
        }
      }
    }

    &-nedmag {
      background: $color-nedmag-secondary;
    }
  }
}
