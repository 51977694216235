.h-ornament {
  color: inherit;
  opacity: 0;
  position: absolute;
  pointer-events: none;
  visibility: hidden;
  z-index: 6;

  &:not(.h-ornament--navigation) svg > g > g {
    filter: none !important;
  }

  & svg {
    width: auto !important;
    height: auto !important;
  }

  svg > g > g {
    & > g {
      opacity: 1 !important;

      & > g {
        opacity: 1 !important;

        & > g {
          opacity: 1 !important;
        }
      }
    }
  }

  & svg > g > g > g > g > path {
    stroke: white !important;
    stroke-opacity: 0.2;

    &:not(:first-child) {
      display: none;
    }
  }

  & svg > g > g > g > path {
    stroke-opacity: 0.2;
  }

  .t-layout--theme-white &,
  .m-navigation & {
    & svg > g > g > g > g > path {
      stroke: black !important;
    }
  }

  &--reveal {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.1s $ease, visibility 0.1s $ease;
  }

  &--footer {
    left: 1px;
    transform: translate3d(-50%, -15%, 0);
    top: 0;
  }

  &--header {
    &-top {
      top: 1rem;
      transform: translate3d(-50%, -25%, 0);

      &.h-ornament--large {
        top: -4 * $space-huge;
      }
    }

    &-center {
      top: 10vh;
      transform: translate3d(-50%, -50%, 0);
    }

    &-bottom {
      top: 80vh;
      transform: translate3d(-50%, -100%, 0);
    }
  }

  &--right {
    bottom: 0;
    right: 0;
    top: auto;
    transform: translate3d(50%, 0, 0);
  }

  &--left {
    bottom: 0;
    left: 0;
    transform: translate3d(-50%, 0, 0);
  }

  &--bottom {
    &-right {
      left: 0;
      bottom: 0;
      transform: translate3d(-50%, 50%, 0);
    }

    &-left {
      bottom: 0;
      left: 0;
      transform: translate3d(-50%, 70%, 0);
    }
  }

  &--middle {
    &-right {
      top: 50%;
      transform: translate3d(-50%, 0, 0);
    }
  }

  &--top {
    &-right {
      top: 0;
      transform: translate3d(-50%, -75%, 0);
    }
  }

  &--navigation {
    left: 1.5px;
    transform: translate3d(-50%, -40%, 0);
    top: 0;
  }

  &--landings-page {
    &.h-ornament--right {
      transform: translate3d(50%, 75, 0);
    }

    &.h-ornament--top-right {
      right: 0;
      transform: translate3d(50%, -150%, 0);
    }
  }

  @include breakpoint-down(medium) {
    display: none;
  }
  @include breakpoint-up(large) {
    display: block;
  }
}
