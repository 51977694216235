.u-text {
  &-align {
    &--left {
      text-align: left !important;
    }

    &--center {
      text-align: center !important;
    }

    &--right {
      text-align: right !important;
    }
  }

  &-color {
    &--default {
      color: $color-default !important;
    }

    &--primary {
      color: $color-primary !important;
    }

    &--secondary {
      color: $color-secondary !important;
    }

    &--tertiary {
      color: $color-tertiary !important;
    }

    &--quaternary {
      color: $color-quaternary !important;
    }

    &--white {
      color: white !important;
    }

    &--black {
      color: black !important;
    }

    &--light {
      color: $color-light !important;
    }

    &--gray {
      color: $color-gray !important;
    }

    &--highlight {
      color: $color-highlight !important;
    }

    &--case {
      color: $color-case;
    }

    &--tynaarlo {
      &-primary {
        color: $color-tynaarlo-primary !important;
      }

      &-secondary {
        color: $color-tynaarlo-secondary !important;
      }

      &-quaternary {
        color: $color-tynaarlo-quaternary !important;
      }
    }

    &--signify {
      &-primary {
        color: $color-signify-primary !important;
      }

      &-secondary {
        color: $color-signify-secondary !important;
      }
    }
  }

  &-family {
    &--heading {
      font-family: $font-family-heading !important;
    }

    &--default {
      font-family: $font-family-default !important;
    }
  }

  &-weight {
    &--light {
      font-weight: lighter !important;
    }

    &--normal {
      font-weight: $font-weight-base !important;
    }

    &--medium {
      font-weight: $font-weight-medium !important;
    }

    &--bold {
      font-weight: $font-weight-bold !important;
    }
  }

  &-style {
    &--italic {
      font-style: italic !important;
    }
  }

  &-line-height {
    &--small {
      line-height: $line-height-small !important;
    }

    &--medium {
      line-height: $line-height-medium !important;
    }

    &--large {
      line-height: $line-height-large !important;
    }

    &--huge {
      line-height: $line-height-huge !important;
    }
  }

  &-decoration {
    &--underline {
      text-decoration: underline !important;

      & .m-lettering__word-inner {
        text-decoration: underline !important;
      }
    }

    &--none {
      text-decoration: none !important;
    }
  }

  &-size {
    &--small {
      font-size: $font-size-small !important;
    }

    &--base {
      font-size: $font-size-base !important;
    }

    &--large {
      font-size: 1rem !important;

      @include breakpoint-up(tiny) {
        font-size: 1.125rem !important;
      }

      @include breakpoint-up(medium) {
        font-size: $font-size-large !important;
      }
    }

    &--huge {
      font-size: 1.125rem !important;

      @include breakpoint-up(tiny) {
        font-size: 1.25rem !important;
      }

      @include breakpoint-up(medium) {
        font-size: $font-size-huge !important;
      }
    }

    &--extra-huge {
      font-size: 1.25rem !important;

      @include breakpoint-up(small) {
        font-size: 1.5rem !important;
      }

      @include breakpoint-up(medium) {
        font-size: $font-size-extra-huge !important;
      }

      &-from-small {
        font-size: 1.25rem !important;

        @include breakpoint-up(small) {
          font-size: 1.5rem !important;
        }
      }
    }

    &--h1 {
      font-size: 2rem !important;

      @include breakpoint-up(tiny) {
        font-size: 2.5rem !important;
      }

      @include breakpoint-up(small) {
        font-size: 3.125rem !important;
      }

      @include breakpoint-up(medium) {
        font-size: $font-size-h1 !important;
      }
    }

    &--h2 {
      font-size: 1.75rem !important;

      @include breakpoint-up(tiny) {
        font-size: 2rem !important;
      }

      @include breakpoint-up(small) {
        font-size: 2.5rem !important;
      }

      @include breakpoint-up(medium) {
        font-size: $font-size-h2 !important;
      }
    }

    &--h3 {
      font-size: 1.5rem !important;

      @include breakpoint-up(tiny) {
        font-size: 1.75rem !important;
      }

      @include breakpoint-up(small) {
        font-size: 2rem !important;
      }

      @include breakpoint-up(medium) {
        font-size: $font-size-h3 !important;
      }
    }

    &--h4 {
      font-size: 1.375rem !important;

      @include breakpoint-up(tiny) {
        font-size: 1.5rem !important;
      }

      @include breakpoint-up(small) {
        font-size: 2rem !important;
      }

      @include breakpoint-up(medium) {
        font-size: $font-size-h4 !important;
      }
    }

    &--h5 {
      font-size: 1.375rem !important;

      @include breakpoint-up(tiny) {
        font-size: 1.5rem !important;
      }

      @include breakpoint-up(small) {
        font-size: 2rem !important;
      }

      @include breakpoint-up(medium) {
        font-size: $font-size-h4 !important;
      }
    }

    &--h6 {
      font-size: 1.375rem !important;

      @include breakpoint-up(tiny) {
        font-size: 1.5rem !important;
      }

      @include breakpoint-up(small) {
        font-size: 2rem !important;
      }

      @include breakpoint-up(medium) {
        font-size: $font-size-h4 !important;
      }
    }

    &--link {
      &-quote {
        font-size: 1.125rem !important;

        @include breakpoint-up(tiny) {
          font-size: 1.375rem !important;
        }

        @include breakpoint-up(small) {
          font-size: 1.625rem !important;
        }

        @include breakpoint-up(medium) {
          font-size: 1.875rem !important;
        }

        @include breakpoint-up(large) {
          font-size: $font-size-h4 !important;
        }
      }
    }

    &--case {
      &-header {
        font-size: 1.75rem !important;

        @include breakpoint-up(tiny) {
          font-size: 2.5rem !important;
        }

        @include breakpoint-up(small) {
          font-size: 3.5rem !important;
        }

        @include breakpoint-up(medium) {
          font-size: 4.25rem !important;
        }
      }

      &-paragraph {
        font-size: 1.125rem !important;

        @include breakpoint-up(tiny) {
          font-size: 1.3rem !important;
        }

        @include breakpoint-up(small) {
          font-size: 1.8rem !important;
        }

        @include breakpoint-up(medium) {
          font-size: 2rem !important;
        }
      }
    }

    &__header {
      &--service {
        @include breakpoint-up(medium) {
          font-size: 3.8rem !important;
        }

        @include breakpoint-up(huge) {
          font-size: 4rem !important;
        }

        @include breakpoint-up(giant) {
          font-size: 4.375rem !important;
        }
      }
    }
  }
}
