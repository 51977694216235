.m-visual {
  left: 0;
  margin: 0 auto;
  max-width: 1200px;
  position: relative;
  right: 0;
  height: 400px;
  width: 100vw;
  z-index: 10;

  @include breakpoint-up(medium) {
    height: 100%;
    left: 0;
    position: absolute;
    width: 100vw;
  }

  @include breakpoint-up(large) {
    left: 10vw;
    right: 10vw;
    width: 80vw;
  }

  @include breakpoint-up(huge) {
    left: 20vw;
    right: 20vw;
    width: 60vw;
  }

  &__card {
    position: relative;
    z-index: 15;

    @include breakpoint-up(medium) {
      margin-top: $space-container;

      &.m-card--theme-quaternary {
        margin-bottom: $space-container;
      }
    }
  }

  &_p4ss {
    @include breakpoint-down(medium) {
      display: flex;
      margin: 0 auto;
      width: 75%;
    }
  }
}
